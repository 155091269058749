import React, {useState} from 'react';
import {Box, CardActionArea, IconButton, Typography, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import InfoIcon from '@mui/icons-material/Info';
import ahu from '../../assets/Images/anesi_ahu_id.png';
import gahp from '../../assets/Images/anesi_gahp_od.png';
import {CustomNameUtils} from "../../Utils/CustomNameUtils";
import AppCard from "../shared/AppCard";
import DeleteIcon from '@mui/icons-material/Delete';
import AppDialog from "../shared/AppDialog";
import {useApi} from "../../contexts/ApiProvider";
import SuccessAnimation from "../../assets/Lotties/SuccessAnimation";

const InfoContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    justifyContent: 'space-evenly',
    alignItems: 'start',
    gap: '2em',
});

const UnitInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
});

function UnitCard({ unit, contractor, navigate, setDrawerOpen, setSelectedUnit }) {
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const api = useApi();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('confirm'); // 'confirm' or 'success'
    const [unitToDelete, setUnitToDelete] = useState(null);

    const handleDeleteClick = (unit) => {
        setUnitToDelete(unit);
        setDialogContent('confirm');
        setConfirmationOpen(true);
    };

    const handleDialogClose = () => {
        setConfirmationOpen(false);
        setUnitToDelete(null);
    };

    const handleDialogConfirm = async () => {
        if (!unitToDelete) return;

        try {
            const response = await api.delete('/me/units', { units: [unitToDelete.id]});
            if (response.ok) {
                setDialogContent('success');
            } else {
                console.log('Failed to remove unit');
            }
        } catch (error) {
            console.error('An error occurred while removing the unit', error);
        }
    };

    const renderDialogContent = () => {
        if (dialogContent === 'confirm') {
            return (
                <Typography variant="subtitle2">
                    {contractor
                        ? 'Are you sure you want to remove this unit from your account? You will lose the ability to see all data associated if you choose to continue.'
                        : 'Are you sure you want to remove this unit from your residence? If you choose to continue you will not be able to access any data going forward.'
                    }
                </Typography>
            );
        } else if (dialogContent === 'success') {
            return (
                <>
                    <SuccessAnimation />
                    <Typography variant="subtitle2">
                        The unit has been successfully removed.
                    </Typography>
                </>
            );
        }
    };

    return (
        <AppCard type="good" key={unit.id}>
            <Box display="flex" alignItems="center">
                <CardActionArea onClick={() => navigate(`${unit.id}`)}>
                    <InfoContainer>
                        <Box style={{ height: '8em', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={unit.unitType === "Outdoor" ? gahp : ahu}
                                alt="Anesi Unit"
                                style={{ maxHeight: isSmallScreen ? '4em' : '5em', maxWidth: isSmallScreen ? '5em' : '6em', height: 'auto' }}
                            />
                        </Box>
                        <UnitInfo>
                            <Typography fontWeight="bold" fontSize={isSmallScreen ? "0.9em" : "1em"}>{CustomNameUtils(unit, contractor)}</Typography>
                            <Typography fontSize={isSmallScreen ? "0.9em" : "1em"} fontWeight="700" color="#989898">SN: {unit.serial_number}</Typography>
                            <Typography fontSize={isSmallScreen ? "0.9em" : "1em"} fontWeight="700" color={unit.faults && unit.faults.length > 0 ? 'red' : '#46B67D'}>
                                {unit.faults && unit.faults.length > 0 ? 'SYSTEM ERROR' : 'SYSTEM OK'}
                            </Typography>
                        </UnitInfo>
                    </InfoContainer>
                </CardActionArea>
                {contractor && (
                    <IconButton onClick={() => { setDrawerOpen(true); setSelectedUnit(unit); }}>
                        <InfoIcon style={{ color: '#37589D', cursor: 'pointer' }} />
                    </IconButton>
                )}
                <IconButton onClick={() => handleDeleteClick(unit)}>
                    <DeleteIcon sx={{ marginRight: '-0.5em'}} color="error" style={{ cursor: 'pointer' }} />
                </IconButton>
            </Box>
            <AppDialog
                open={confirmationOpen}
                title={dialogContent === 'confirm' ? 'Confirm Unit Removal?' : 'Removal Successful!'}
                handleClose={handleDialogClose}
                handleConfirm={dialogContent === 'confirm' ? handleDialogConfirm : handleDialogClose}
                confirmText={dialogContent === 'confirm' ? 'Remove' : 'Close'}
                cancelText={dialogContent === 'confirm' ? 'Cancel' : null}
            >
                {renderDialogContent()}
            </AppDialog>
        </AppCard>
    );
}

export default UnitCard;
