import React from 'react';
import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";

const UnitDetailsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1em',
});

const StatusTypography = styled(Typography)(({ theme, status }) => ({
    color: status === 'OK' ? '#4EB6B5' : '#B1494A',
}));

function UnitHeader({unit, sn, status}) {

    return (
        <UnitDetailsContainer>
            {unit && (
                <Typography fontWeight="600" style={{ color: '#989898' }}>Unit: {unit}</Typography>
            )}
            <Typography fontWeight="600" style={{ color: '#989898' }}>SN: {sn}</Typography>
            {status && (
                <StatusTypography fontWeight="600" status={status}>System: {status}</StatusTypography>
            )}
        </UnitDetailsContainer>
    );
}

export default UnitHeader;
