import React, {useContext, useState} from 'react';
import styled from "@emotion/styled";
import {Box, IconButton, InputAdornment, Typography, useMediaQuery} from "@mui/material";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import AppTextInput from "../shared/AppTextInput";
import AppButton from "../shared/AppButton";
import {MultiStepContext} from "../../contexts/MultiStepContext";
import {StepContext} from "../../contexts/StepContext";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Link} from 'react-router-dom';
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
    textAlign: 'center',
});

const InputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '1em',
    width: '100%',
    gap: '.75em',
});

const AndContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '.25em',
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3em',
    marginTop: '1em',
});

const QuestionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '1em',
});

const phoneRegExp = /^\d{10}$/;

const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        phoneNumber: values.userType === 'Contractor'
            ? Yup.string().matches(phoneRegExp, 'Phone Number must be exactly 10 digits, no characters').required('Phone number is required')
            : Yup.string().notRequired(),
        password: values.userType === 'Customer'
            ? Yup.string()
                .matches(
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    "Must contain 8 characters, one uppercase, one number and one special character"
                )
                .required('Password is required')
            : Yup.string().notRequired(),
        confirmPassword: values.userType === 'Customer'
            ? Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required')
            : Yup.string().notRequired(),
    });
});


function RegisterOne(props) {
    const api = useApi();
    const alert = useAlert();
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const [showInputs, setShowInputs] = useState(false);
    const { formState, setFormState } = useContext(MultiStepContext);
    const { nextStep } = useContext(StepContext);
    const { userType } = formState;
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const handleQuestion = (answer) => {
        const userType = answer ? 'Contractor' : 'Customer';
        console.log(answer);
        console.log(userType);
        setFormState(prevState => ({ ...prevState, userType }));
        setShowInputs(true);
    };

    const fetchUserData = async (email, phoneNumber) => {
        const formData = {
            email: email,
            phone_number: phoneNumber,
        }
        try {
            return await api.post('/con_registration', formData)
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };


    const handleContinue = async (values) => {
        const sanitizedPhoneNumber = values.phoneNumber.replace(/\D/g, '');
        const updatedValues = { ...values, phoneNumber: sanitizedPhoneNumber };

        setFormState(prevState => ({ ...prevState, ...updatedValues }));

        if (userType === 'Contractor') {
            try {
                const userData = await fetchUserData(updatedValues.email, sanitizedPhoneNumber);
                console.log(userData);
                if (userData && userData.status === 200) {
                    if (userData.body.password) {
                        alert('Your account is already created', 'success');
                        return;
                    }
                    setFormState(prevState => ({
                        ...prevState,
                        firstName: userData.body.first_name,
                        lastName: userData.body.last_name,
                        email: userData.body.email,
                        phoneNumber: sanitizedPhoneNumber,
                        companyName: userData.body.company_id,
                        userType
                    }));
                    nextStep();
                } else {
                    alert('This account was not found', 'error');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        } else {
            nextStep();
        }
    };


    return (
        <>
            {!showInputs ? (
                <TextContainer>
                    <Typography style={{ fontSize: isSmallScreen ? '1em' : '1.25em'}} fontWeight="700">Welcome to Anesi</Typography>
                    <Typography style={{ fontSize: isSmallScreen ? '1em' : '1.25em'}} fontWeight="700">Are you a contractor?</Typography>
                    <Box width="100%" border="2px solid #4EB6B5" borderRadius="0.75em" p={1} mt={2}>
                        <Typography fontWeight="700" color='#959292'>
                            Customers / Homeowners select no.
                        </Typography>
                        <Typography fontWeight="700" color='#959292'>
                            You will register on the next page.
                        </Typography>
                    </Box>
                    <QuestionContainer>
                        <AppButton  variant="secondary" onClick={() => handleQuestion(false)}>No</AppButton>
                        <AppButton variant="primary" onClick={() => handleQuestion(true)}>Yes</AppButton>
                    </QuestionContainer>
                </TextContainer>
            ) : (
                <>
                    <TextContainer>
                        <Typography  variant={userType === 'Contractor' ? 'subtitle1' : 'h6'}>
                            {userType === 'Contractor' ? 'Ensure you have filled out your pre-registration form. If so, please provide your email and phone number.' : 'Create an Account'}
                        </Typography>
                        <Typography variant="subtitle1" color="#959292">
                            {userType === 'Contractor' ? '' : 'Creating an account takes only moments. Enter your email and create a password below.'}
                        </Typography>
                    </TextContainer>
                    <Formik initialValues={formState} validationSchema={validationSchema} onSubmit={handleContinue}>
                        <Form>
                            <InputContainer>
                                <AppTextInput label="Email" name="email"/>
                                {userType === 'Contractor' && (
                                    <AppTextInput label="Phone Number" name="phoneNumber"/>
                                )}
                                {userType === 'Customer' && (
                                    <>
                                        <AndContainer>
                                            <Typography>
                                                and
                                            </Typography>
                                        </AndContainer>
                                        <AppTextInput
                                            type={showPassword ? "text" : "password"}
                                            label="Password"
                                            name="password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <AppTextInput
                                            type={showPassword ? "text" : "password"}
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            </InputContainer>
                            <ButtonContainer>
                                <AppButton variant="primary" type="submit">{userType === 'Contractor' ? 'Finish set up' : 'Create an account!'}</AppButton>
                                <Typography mt={1} fontSize="1em" color="#959292" style={{ textAlign: 'center', fontWeight: '700'}}>
                                    Already have an account? <Link to="/login">Log in</Link>
                                </Typography>
                            </ButtonContainer>
                        </Form>
                    </Formik>
                </>
            )}
        </>
    );
}

export default RegisterOne;
