import React from 'react';
import {Box, Typography, Tooltip, Checkbox, List, InputAdornment, Button, TextField, Card} from "@mui/material";
import AppCard from "../shared/AppCard";
import AppDivider from "../shared/AppDivider";
import AppButton from "../shared/AppButton";
import { CustomNameUtils } from "../../Utils/CustomNameUtils";
import SearchIcon from '@mui/icons-material/Search';
import styled from "@emotion/styled";

const StyledInput = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#37589D',
            borderWidth: 1,
            borderRadius: 12,
        },
        '&.Mui-focused fieldset': {
            borderColor: '#37589D',
        },
        '& .MuiInputBase-input': {
            height: '2.5em',
            padding: '0 1em',
        },
    },
});

const StyledSearchInput = styled(TextField)({
    marginTop: '2em',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#37589D',
            borderWidth: 1,
            borderRadius: 12,
        },
        '&.Mui-focused fieldset': {
            borderColor: '#37589D',
        },
        '& .MuiInputBase-input': {
            height: '2.5em',
            padding: '0 1em',
        },
    },
});

const ListCard = styled(AppCard)({
    '& .MuiCardContent-root': {
        padding: 0,
    }
});

export function UnitInfoContent({ selectedUnit, handleToolTip, tooltipOpen, fullAddress, installAddress, loggedInUser }) {
    console.log(selectedUnit)

    const getUserLabel = (user) => {
        if (user.id === loggedInUser.id) {
            return 'Me';
        } else if (user.role === 0) {
            return 'Owner';
        } else {
            return 'Contractor';
        }
    };

    return (
        <>
            <Typography fontWeight="700" color="#959292" mb={0.5}>
                Owner Information:
            </Typography>
            <AppCard>
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">Name</Typography>
                    <Typography fontWeight="700">
                        {selectedUnit && selectedUnit.users && selectedUnit.users.length > 0 ? (
                            (() => {
                                const owner = selectedUnit.users.find(user => user.role === 0);
                                return owner ? `${owner.first_name} ${owner.last_name}` : 'Unavailable';
                            })()
                        ) : 'Unavailable'}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">Primary address</Typography>
                    <Tooltip
                        title={fullAddress}
                        placement="bottom"
                        onClick={handleToolTip}
                        open={tooltipOpen}
                        disableHoverListener
                        arrow
                    >
                        <Typography color="#37589D" style={{ cursor: 'pointer' }} fontWeight="700">See Address</Typography>
                    </Tooltip>
                </Box>
            </AppCard>
            <Typography fontWeight="700" color="#959292" mb={0.5} mt={2}>
                Unit Basics:
            </Typography>
            <AppCard>
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">Serial Number</Typography>
                    <Typography fontWeight="700">{selectedUnit && selectedUnit.serial_number}</Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">Installation Date</Typography>
                    <Typography fontWeight="700">  {selectedUnit && selectedUnit.install_date
                        ? new Date(selectedUnit.install_date * 1000).toLocaleDateString()
                        : 'Unavailable'}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">Last Accessed By</Typography>
                    <Typography fontWeight="700">
                        {selectedUnit && selectedUnit.last_maintained_by
                            ? `${selectedUnit.last_maintained_by.first_name.charAt(0)}. ${selectedUnit.last_maintained_by.last_name}`
                            : 'Unavailable'}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">Location</Typography>
                    <Tooltip
                        title={installAddress}
                        placement="bottom"
                        onClick={handleToolTip}
                        open={tooltipOpen}
                        disableHoverListener
                        arrow
                    >
                        <Typography color="#37589D" style={{ cursor: 'pointer' }} fontWeight="700">See Address</Typography>
                    </Tooltip>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">Firmware Version</Typography>
                    <Typography fontWeight="700">
                        {selectedUnit && selectedUnit.firmware
                            ? `${selectedUnit.firmware.fw_major}.${selectedUnit.firmware.fw_minor}`
                            : 'Unavailable'}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between" flexDirection="column">
                    <Typography fontWeight="600" color="#959292">Data Accessible To</Typography>
                    {selectedUnit && selectedUnit.users && selectedUnit.users.length > 0 ? (
                        selectedUnit.users.map((user, index) => (
                            <Typography key={index} fontWeight="700" fontSize="0.9em">
                                {user.first_name.charAt(0)}. {user.last_name} - {getUserLabel(user)}
                            </Typography>
                        ))
                    ) : (
                        <Typography fontWeight="600">No users available</Typography>
                    )}
                </Box>
            </AppCard>
        </>
    );
}

export function CreateGroupContent({ groupName, setGroupName, searchGroupTerm, setSearchGroupTerm, filteredGroupUnits, selectedGroupUnits, toggleUnitSelection, handleSaveGroup, contractor }) {
    return (
        <>
            <Box mt={1} position="relative">
                <Typography fontWeight="700" color="#959292" mb={1}>
                    Name your group:
                </Typography>
                <StyledInput
                    fullWidth
                    variant="outlined"
                    placeholder="Group name"
                    value={groupName}
                    onChange={e => setGroupName(e.target.value)}
                    InputProps={{ style: { borderRadius: 10 } }}
                />
            </Box>
            <Box>
                <Typography fontWeight="700" color="#959292" mt={1}>
                    Search units available to you:
                </Typography>
                <StyledSearchInput
                    fullWidth
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    variant="outlined"
                    placeholder="Search units..."
                    value={searchGroupTerm}
                    onChange={e => setSearchGroupTerm(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{ color: '#37589D' }} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography fontWeight="700" color="#959292" mb={1}>
                    Search Results:
                </Typography>
                <Card variant="outlined" sx={{ borderColor: '#37589D', borderRadius: 3, padding: 1, maxHeight: '60vh', overflowY: 'scroll' }}>
                    {filteredGroupUnits && (
                        filteredGroupUnits.filter(unit => unit.id).length > 0 ? (
                            filteredGroupUnits.filter(unit => unit.id).map(unit => (
                                <Box key={unit.id} display="flex" alignItems="center">
                                    <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.25em' }, color: '#37589D', '&.Mui-checked': { color: '#4EB6B5' } }}
                                        checked={selectedGroupUnits.includes(unit.id)}
                                        onChange={() => toggleUnitSelection(unit.id)}
                                    />
                                    <Box>
                                        <Typography fontWeight="700">
                                            {CustomNameUtils(unit, contractor)}
                                        </Typography>
                                        <Typography color="#959292" fontWeight="700" fontSize="0.9em">
                                            SN: {unit.serial_number}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography fontWeight="700" color="#959292">
                                No units match your search.
                            </Typography>
                        )
                    )}
                </Card>
            </Box>
            <Typography fontSize="0.75em" textAlign="center" fontWeight="700" color="#959292" mt={1}>
                The same unit may be grouped numerous times.
            </Typography>
            <Box mt={3} display="flex" justifyContent="center">
                <AppButton color="primary" onClick={handleSaveGroup} disabled={groupName === '' || selectedGroupUnits.length === 0}>
                    Save Group
                </AppButton>
            </Box>
        </>
    );
}

export function ManageGroupUnitsContent({ unitsInGroup, selectedInGroup, setSelectedInGroup, toggleSelected, moveUnits, unitsAvailable, selectedAvailableUnits, setSelectedAvailableUnits, contractor }) {
    return (
        <>
            <Box mt={1} mb={1}>
                <Typography fontWeight="700" color="#959292" textAlign="center">
                    Move units between this group and the units available to you.
                </Typography>
            </Box>
            <AppDivider />
            <Box display="flex" flexDirection="column" mt={2}>
                <Box>
                    <Typography fontWeight="700" color="#959292" mb={1}>
                        Units in Group:
                    </Typography>
                    <ListCard>
                        <List>
                            {unitsInGroup.map((unit, index) => (
                                <Box key={index} display="flex" alignItems="center" style={{ marginBottom: '0.5em' }}>
                                    <Checkbox
                                        checked={selectedInGroup.includes(unit.id)}
                                        onChange={() => toggleSelected(unit.id, setSelectedInGroup, selectedInGroup)}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.25em' }, color: '#37589D', '&.Mui-checked': { color: '#4EB6B5' } }}
                                    />
                                    <Box>
                                        <Typography fontWeight="700">
                                            {CustomNameUtils(unit, contractor)}
                                        </Typography>
                                        <Typography color="#959292" fontWeight="700">
                                            SN: {unit.serial_number}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </List>
                    </ListCard>
                </Box>
                <Box gap="1em" display="flex" flexDirection="row" alignItems="center" justifyContent="center" mb={1} mt={1}>
                    <Button
                        style={{ borderColor: unitsInGroup.length <= 1 ? 'lightgrey' : '#4EB6B5', borderWidth: '0.15em' }}
                        variant="outlined"
                        size="small"
                        disabled={unitsInGroup.length <= 1}
                        onClick={() => moveUnits('fromGroup')}
                    >
                        ↓
                    </Button>
                    <Button
                        onClick={() => moveUnits('toGroup')}
                        style={{ borderColor: '#4EB6B5', borderWidth: '0.15em' }} variant="outlined" size="small"
                    >
                        ↑
                    </Button>
                </Box>
                <Box>
                    <Typography fontWeight="700" color="#959292" mb={1}>
                        Units Available:
                    </Typography>
                    <ListCard>
                        <List>
                            {unitsAvailable.length > 0 ? (
                                unitsAvailable.map((unit, index) => (
                                    <Box key={index} display="flex" alignItems="center" style={{ marginBottom: '0.5em' }}>
                                        <Checkbox
                                            checked={selectedAvailableUnits.includes(unit.id)}
                                            onChange={() => toggleSelected(unit.id, setSelectedAvailableUnits, selectedAvailableUnits)}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: '1.25em' }, color: '#37589D', '&.Mui-checked': { color: '#4EB6B5' } }}
                                        />
                                        <Box>
                                            <Typography fontWeight="700">
                                                {CustomNameUtils(unit, contractor)}
                                            </Typography>
                                            <Typography color="#959292" fontWeight="700">
                                                SN: {unit.serial_number}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Typography fontSize="1.25em" color="#959292" fontWeight="700" textAlign="center">
                                    No units available.
                                </Typography>
                            )}
                        </List>
                    </ListCard>
                </Box>
            </Box>
        </>
    );
}
