import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import AppCard from "../components/shared/AppCard";
import styled from "@emotion/styled";
import {Box, Card, CardContent, CardHeader, Collapse, Drawer, IconButton, Typography} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import AppDivider from "../components/shared/AppDivider";
import {useUser} from "../contexts/UserProvider";
import {useApi} from "../contexts/ApiProvider";
import {useAlert} from "../contexts/AlertsProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const MainContent = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75em',
});

const CardContainer = styled(Box)({
    marginBottom: '0.25em',
})

const UserSelect = styled(Box)({
    color: 'black',
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '700',
});

const StyledCard = styled(AppCard)({
   borderColor:  '#4EB6B5',
});

const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
        borderTop: '7px solid transparent',
        borderRadius: '20px 20px 0 0',
        height: '60%',
        padding: '16px',
    },
});

function SettingsScreen(props) {
    const {user, setUser} = useUser();
    const contractor = user.role !== 0;
    const api = useApi();
    const alert = useAlert();
    const notificationsEnabled = user.phone_numbers[0]?.enable_alerts;
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [selectedUnits, setSelectedUnits] = useState('Metric');
    const [selectedMethods, setSelectedMethods] = useState({Email: user?.notify_email ?? false, SMS: user?.notify_sms ?? false});
    const [selectedErrorTypes, setSelectedErrorTypes] = useState({ Critical: user.notify_error, Warnings: user.notify_warning });
    const [isMethodExpanded, setMethodExpanded] = useState(false);
    const [isErrorTypesExpanded, setErrorTypesExpanded] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);


    useEffect(() => {
        setSelectedLanguage(user?.language === 0 ? 'English' : 'French');
        setSelectedUnits(user?.metric === true ? 'Metric' : 'Imperial');
        setSelectedMethods({Email: user?.notify_email ?? false, SMS: user?.notify_sms ?? false,});
        setSelectedErrorTypes({Critical: user?.notify_error ?? false, Warnings: user?.notify_warning ?? false,});
    }, [user]);

    const updateNotificationSettings = async (field, value) => {
        const payload = { [field]: value };
        const response = await api.put('/me', payload);
        if (response.ok) {
            setUser(prevState => ({
                ...prevState,
                [field]: value
            }));
            alert('Notification settings have been updated', 'success');
        } else {
            alert('Could not update notification settings, please try again', 'error');
        }
    };

    const handleSave = async (newLanguage, newUnits) => {
        const language = newLanguage !== null ? newLanguage : (selectedLanguage === 'English' ? 0 : 1);
        const metric = newUnits !== null ? newUnits : (selectedUnits === 'Metric');
        const response = await api.put('/me', {
            language: language,
            metric: metric,
        });
        if (response.ok) {
            setUser(response.body);
            alert('Updates successfully saved', 'success');
        } else {
            alert('Could not update settings, please try again', 'error');
        }
    };

    return (
        <AppContainer>
            <AppHeader title="Settings" backButtonRoute={`/dashboard`} />
            <MainContent>
                <CardContainer>
                    <Typography color="#959292" fontWeight="700" gutterBottom>
                        Preferred Language:
                    </Typography>
                    <StyledCard>
                        <UserSelect onClick={() => {
                            const newLanguage = 'English';
                            setSelectedLanguage(newLanguage);
                            handleSave(0, null);
                        }}>
                            English
                            {selectedLanguage === 'English' && <CheckIcon style={{ color: "#4EB6B5" }} />}
                        </UserSelect>
                        <AppDivider inCard="true"/>
                        <UserSelect onClick={() => {
                            const newLanguage = 'French';
                            setSelectedLanguage(newLanguage);
                            handleSave(1, null);
                        }}>
                            French
                            {selectedLanguage === 'French' && <CheckIcon style={{ color: "#4EB6B5" }} />}
                        </UserSelect>
                    </StyledCard>
                </CardContainer>
                <CardContainer>
                    <Typography color="#959292" fontWeight="700" gutterBottom>
                        Preferred Units:
                    </Typography>
                    <StyledCard>
                        <UserSelect onClick={() => {
                            const newUnits = 'Metric';
                            setSelectedUnits(newUnits);
                            handleSave(null, true);
                        }}>
                            Metric
                            {selectedUnits === 'Metric' && <CheckIcon style={{ color: "#4EB6B5" }} />}
                        </UserSelect>
                        <AppDivider inCard="true"/>
                        <UserSelect onClick={() => {
                            const newUnits = 'Imperial';
                            setSelectedUnits(newUnits);
                            handleSave(null, false);
                        }}>
                            Imperial
                            {selectedUnits === 'Imperial' && <CheckIcon style={{ color: "#4EB6B5" }} />}
                        </UserSelect>
                    </StyledCard>
                </CardContainer>
                {notificationsEnabled && (
                    <CardContainer>
                        <Typography color="#959292" fontWeight="700" gutterBottom>
                            Notifications Settings:
                        </Typography>
                        <StyledCard >
                            <Card variant="outlined" sx={{ borderRadius: '8px'}}>
                                <CardHeader
                                    title={
                                        <Typography fontWeight="700" fontSize='16'>
                                            Methods
                                        </Typography>
                                    }
                                    action={
                                        <ExpandMoreIcon
                                            fontSize='medium'
                                            style={{ color: '#37589D', transform: isMethodExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                            onClick={() => setMethodExpanded(!isMethodExpanded)}
                                        />
                                    }
                                />
                                <Collapse in={isMethodExpanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        <UserSelect onClick={() => updateNotificationSettings('notify_email', !selectedMethods.Email)}>
                                            <Box display='flex' flexDirection="row">
                                                <EmailIcon style={{ color: '#37589D'}} />
                                                <Typography ml={1} fontWeight="600" fontSize='17'>
                                                    Email
                                                </Typography>
                                            </Box>
                                            {selectedMethods.Email && <CheckIcon style={{ color: "#4EB6B5" }} />}
                                        </UserSelect>
                                        <AppDivider inCard="true"/>
                                        <UserSelect onClick={() => updateNotificationSettings('notify_sms', !selectedMethods.SMS)}>
                                            <Box display='flex' flexDirection="row">
                                                <SmsIcon style={{ color: '#37589D'}}/>
                                                <Typography ml={1} fontWeight="600" fontSize='17'>
                                                    SMS
                                                </Typography>
                                            </Box>
                                            {selectedMethods.SMS && <CheckIcon style={{ color: "#4EB6B5" }} />}
                                        </UserSelect>
                                    </CardContent>
                                    <Typography color="#959292" ml={2} fontSize="0.75em" textAlign="center" fontWeight="700">
                                        Message and data rates may apply.
                                    </Typography>
                                </Collapse>
                            </Card>
                            <Card variant="outlined" style={{ marginTop: '0.3em', borderRadius: '8px' }} >
                                <CardHeader
                                    title={
                                        <Typography fontWeight="700" fontSize="17">
                                            Notification Types
                                        </Typography>
                                    }
                                    action={
                                        <ExpandMoreIcon
                                            fontSize='medium'
                                            style={{ color: '#37589D', transform: isErrorTypesExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                            onClick={() => setErrorTypesExpanded(!isErrorTypesExpanded)}
                                        />
                                    }
                                />
                                <Collapse in={isErrorTypesExpanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        {contractor && (
                                            <>
                                                <UserSelect onClick={() => updateNotificationSettings('notify_warning', !selectedErrorTypes.Warnings)}>
                                                    <Box display='flex' flexDirection="row">
                                                        <WarningIcon style={{ color: '#37589D'}}/>
                                                        <Typography ml={1} fontWeight="600">
                                                            Warnings
                                                        </Typography>
                                                    </Box>
                                                    {selectedErrorTypes.Warnings && <CheckIcon style={{ color: "#4EB6B5" }} />}
                                                </UserSelect>
                                                <AppDivider inCard="true"/>
                                            </>
                                        )}
                                        <UserSelect onClick={() => updateNotificationSettings('notify_error', !selectedErrorTypes.Critical)}>
                                            <Box display='flex' flexDirection="row">
                                                <ReportIcon style={{ color: '#37589D'}}/>
                                                <Typography ml={1} fontWeight="600" >
                                                    Lockout
                                                </Typography>
                                            </Box>
                                            {selectedErrorTypes.Critical && <CheckIcon style={{ color: "#4EB6B5" }} />}
                                        </UserSelect>
                                    </CardContent>
                                </Collapse>
                            </Card>
                        </StyledCard>
                        <Box display="flex" alignItems="center" padding={0.5}>
                            <Typography color="#959292" fontWeight="600">
                                Learn more
                            </Typography>
                            <IconButton sx={{ padding: 0}} onClick={() => setDrawerOpen(true)} style={{ cursor: 'pointer' }}>
                                <ArrowDropUpIcon sx={{ color: '#37589D'}} />
                            </IconButton>
                        </Box>
                    </CardContainer>
                )}
                <Box display="flex" flexDirection="column" mt="auto">
                    <Typography textAlign="center" fontWeight="600" fontSize="0.75em" >
                        Anesi App Version: 1.63.0
                    </Typography>
                </Box>
            </MainContent>
            {/*Eventually use custom drawer component...*/}
            <StyledDrawer
                anchor="bottom"
                open={isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                    <Typography variant="h6" gutterBottom>
                        Types of notifications
                    </Typography>
                    <IconButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon fontSize="small"  />
                    </IconButton>
                </Box>
                <Box mt={1}>
                    <Typography fontWeight="700">
                        Hard Lockouts:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Hard lockout notifications inform you when the system is down and will not restart. The unit will require service to restore operation.
                    </Typography>
                    {contractor && (
                        <>
                            <Typography fontWeight="700">
                                Soft Lockouts:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Soft lockout notifications inform you when the system has detected a fault condition. The unit will restart after a preset timeframe depending on the fault detected. Repeated warnings indicate service is required.
                            </Typography>
                            <Typography fontWeight="700">
                                Warnings:
                            </Typography>
                            <Typography variant="body2">
                                Warnings indicate parameters that are out of a specified range. Warnings do not lock out unit operation. Multiple, repeated warnings may indicate service is required.
                            </Typography>
                        </>
                    )}
                </Box>
            </StyledDrawer>
        </AppContainer>
    );
}

export default SettingsScreen;
