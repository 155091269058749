import {createTheme} from "@mui/material";

const brandTheme = createTheme({
    typography: {
        fontFamily: 'Jura, sans-serif',
        fontWeightLight: 300,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    },
    palette: {
        error: {
            main: '#B1494A',
        },
        warning: {
            main: '#f69292',
        },
        info: {
            main: 'rgba(9,56,165,0.63)',
        },
        success: {
            main: '#4EB6B5',
        },
    },
});

export default brandTheme;
