import { useEffect } from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import styled from "@emotion/styled";
import { Box, IconButton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AppDivider from "../components/shared/AppDivider";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuizIcon from '@mui/icons-material/Quiz';
import { useUnits } from "../contexts/UnitProvider";

const AccordionWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25em',
    paddingTop: '1.5em',
});

const Title = styled(Typography)({
    fontSize: '1.125em',
    fontWeight: '700',
    color: '#37589D',
    '@media (max-width: 359px)': {
        fontSize: '1em',
    },
});

const DocumentLink = ({ title, icon, onClick }) => {
    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    {icon}
                    <Title ml={2}>{title}</Title>
                </Box>
                <IconButton onClick={onClick}>
                    <ChevronRightIcon style={{ color: '#37589D' }} />
                </IconButton>
            </Box>
            <AppDivider />
        </>
    );
};

function DocumentsScreen(props) {
    const { unit, getSpecificUnitData } = useUnits();
    const { unitId } = useParams();

    useEffect(() => {
        if (!unit) {
            getSpecificUnitData(unitId);
        }
    }, [unit, unitId, getSpecificUnitData]);

    const openDocument = (url) => () => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const openFAQ = () => {
        window.open("https://stonemountaintechnologies.com/faq/", '_blank', 'noopener,noreferrer');
    };

    const openAllResources = () => {
        window.open("https://stonemountaintechnologies.com/resources/", '_blank', 'noopener,noreferrer');
    };

    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard/my_units/${unitId}`} title="Documents" />
            <AccordionWrapper>
                <Box display="flex" flexDirection="column" gap="0.5em" mb={3}>
                    <DocumentLink
                        title="Owner's Manual"
                        icon={<PictureAsPdfIcon fontSize="large" style={{ color: '#37589D' }} />}
                        onClick={openDocument("https://stonemountaintechnologies.com/resources/technical-library/#manuals")}
                    />
                    <DocumentLink
                        title="Warranty"
                        icon={<PictureAsPdfIcon fontSize="large" style={{ color: '#37589D' }} />}
                        onClick={openDocument("https://stonemountaintechnologies.com/warranty/")}
                    />
                    <DocumentLink
                        title="Product Specifications"
                        icon={<PictureAsPdfIcon fontSize="large" style={{ color: '#37589D' }} />}
                        onClick={openDocument("https://stonemountaintechnologies.com/resources/technical-library/#datasheets")}
                    />
                    <DocumentLink
                        title="FAQs"
                        icon={<QuizIcon fontSize="large" style={{ color: '#37589D' }} />}
                        onClick={openFAQ}
                    />
                </Box>
                <Box mt={2} display="flex" flexDirection="column">
                    <Typography fontWeight="700" color="#959292" align="left" gutterBottom>
                        See entire collection
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                        <Title color='#37589D' fontWeight="600">All Resources</Title>
                        <IconButton onClick={openAllResources}>
                            <ChevronRightIcon style={{ color: '#37589D' }} />
                        </IconButton>
                    </Box>
                </Box>
            </AccordionWrapper>
        </AppContainer>
    );
}

export default DocumentsScreen;
