import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import AppCard from "../components/shared/AppCard";
import AppButton from "../components/shared/AppButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from "@emotion/styled";
import {Box, Typography, Drawer, Tabs, Tab, Paper, IconButton} from "@mui/material";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import UnitHeader from "../components/unitSpecific/UnitHeader";
import AppFaultsCard from "../components/AppFaultsCard";
import AppPop from "../components/AppPop";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {useNavigate, useParams} from "react-router-dom";
import {useUnits} from "../contexts/UnitProvider";
import {useApi} from "../contexts/ApiProvider";
import AppLoader from "../components/shared/AppLoader";
import {useAlert} from "../contexts/AlertsProvider";
import {useUser} from "../contexts/UserProvider";
import {CustomNameUtils} from "../Utils/CustomNameUtils";

const CardsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4em',
    marginTop: '1em',
});

const ContractorView = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
})

const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
        borderTop: '7px solid transparent',
        borderRadius: '20px 20px 0 0',
        height: '45%',
    },
})

const CustomButton = styled(AppButton)({
    marginTop: '1em',
})

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root': {
        padding: 12,
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} style={{  maxHeight: '55vh', overflowY: 'auto'}} {...other}>
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function FaultsScreen(props) {
    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();
    const [selectedFault, setSelectedFault] = useState(null);
    const {unitId} = useParams()
    const {unit, setUnit, getSpecificUnitData, getSpecificUnitFaults} = useUnits();
    const {user} = useUser();
    const contractor = user.role !== 0;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [clearErrorDialogOpen, setClearErrorDialogOpen] = useState(false);
    const [hasFault, setHasFault] = useState(false);
    const [activeFaults, setActiveFaults] = useState([]);
    const [nonActiveFaults, setNonActiveFaults] = useState([]);
    const handleDrawer = () => {setDrawerOpen(false); };
    const toggleClearErrorDialog = () => setClearErrorDialogOpen(!clearErrorDialogOpen);
    const [loading, setLoading] = useState(false);
    const [confirmationState, setConfirmationState] = useState('confirm');
    const [activeTab, setActiveTab] = useState(0);

    // Below faults are only ones that contractors will ever see in their account. Enums
    const forNonContractor = [11,14,15,19,26];

    useEffect(() => {
        async function fetchUnit() {
            setLoading(true);
            try {
                const fetchedUnit = await getSpecificUnitData(unitId);
                if (fetchedUnit.ok) {
                    setUnit(fetchedUnit.body.unit);
                } else {
                    setUnit(null);
                    alert('Access Denied: You are not the owner of this unit', 'error', 3);
                    navigate('/dashboard');
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        if (!unit) {
            fetchUnit();
        }
    }, [unit, api, unitId]);

    async function fetchFaults(){
        setLoading(true);
        const faultsResponse = await getSpecificUnitFaults(unitId);
        if (faultsResponse.ok) {
            const faultsArray = Object.values(faultsResponse.body);
            let active = faultsArray.filter(fault => fault.active === true);
            let nonActive = faultsArray.filter(fault => fault.active === false);
            if (!contractor) {
                active = active.filter(fault => forNonContractor.includes(fault.enum));
                nonActive = nonActive.filter(fault => forNonContractor.includes(fault.enum));
            }
            setActiveFaults(active);
            setNonActiveFaults(nonActive);
            if (active.length > 0) {
                setHasFault(true);
            } else {
                setHasFault(false);
            }
        } else {
            alert('Could not fetch unit faults', 'error');
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchFaults();
        const interval = setInterval(() => {
            fetchFaults();
        }, 60000);
        return () => clearInterval(interval);
    }, [unit, api, unitId]);

    const clearAllFaults = async () => {
        const response = await api.put(`/units/${unitId}/faults`, '');
        if (response.ok) {
            alert('Faults successfully cleared', 'success');
            return true;
        } else {
            alert('Faults could not be cleared', 'error');
            return false;
        }
    }

    if (!unit || loading) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard/my_units/${unitId}`} title="Alerts" />
            <UnitHeader sn={unit.serial_number} unit={CustomNameUtils(unit, contractor)} status={hasFault ? 'ERROR' : 'OK'}/>
            <Paper elevation={1} style={{ borderRadius: '0.5em', overflow: 'hidden' }}>
                <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    variant="fullWidth"
                    textColor="primary"
                    TabIndicatorProps={{
                        style: {
                            borderRadius: '0.5em 0.5em 0 0',
                            backgroundColor: '#4EB6B5',
                        }
                    }}
                >
                    <Tab
                        style={{
                            fontWeight: '700',
                            fontSize: '1em',
                            color: activeTab === 0 ? '#4EB6B5' : '#989898'
                        }}
                        label="Active"
                    />
                    <Tab
                        style={{
                            fontWeight: '700',
                            fontSize: '1em',
                            color: activeTab === 1 ? '#4EB6B5' : '#989898'
                        }}
                        label="Resolved"
                    />
                </Tabs>
            </Paper>
            {/*The two panels can be written into a single component*/}
            <TabPanel value={activeTab} index={0}>
                <CardsContainer>
                    {activeFaults.length > 0 ? activeFaults.map((fault, index) => (
                        <StyledCard key={fault.id} type="bad">
                            <Box display="flex" flexDirection="row" justifyContent='space-between' alignItems="center" >
                                <Typography color="#989898" fontWeight="700" >
                                    {fault.name}
                                </Typography>
                                {contractor && <InfoOutlinedIcon style={{ color: '#B1494A'}} onClick={() => {
                                    setDrawerOpen(true);
                                    setSelectedFault(fault);
                                }}/>}
                            </Box>
                        </StyledCard>
                    )) : (
                        <Box display="flex" flexDirection="column">
                            <Typography textAlign="center" color="#989898" fontWeight="700" fontSize="1.25em">
                                Good news, <span style={{ color: '#4EB6B5'}}>no active faults</span> detected.
                            </Typography>
                            <ThumbUpAltIcon fontSize="large" style={{ color: '#4EB6B5', alignSelf: 'center', marginTop: '0.5em' }} />
                        </Box>
                    )}
                </CardsContainer>
                {!contractor && (
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" mt={1}>
                        <IconButton>
                            <LocalPhoneIcon style={{ color:'#4EB6B5' }} />
                        </IconButton>
                        <Typography ml={1} fontWeight="700" color="#989898" fontSize="0.9em">
                            Contact my Anesi contractor.
                        </Typography>
                    </Box>
                )}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <CardsContainer>
                    {nonActiveFaults.length > 0 ? nonActiveFaults.map((fault, index) => (
                        <StyledCard key={fault.id}>
                            <Box display="flex" flexDirection="row" justifyContent='space-between' alignItems="center" >
                                <Typography color="#989898" fontWeight="700" >
                                    {fault.name}
                                </Typography>
                                {contractor && <InfoOutlinedIcon style={{ color: '#4EB6B5'}} onClick={() => {
                                    setDrawerOpen(true);
                                    setSelectedFault(fault);
                                }}/>}
                            </Box>
                        </StyledCard>
                    )) : (
                        <Typography textAlign="center" color="#989898" fontWeight="700" fontSize="1.25em">
                           No previous faults found.
                        </Typography>
                    )}
                </CardsContainer>
            </TabPanel>
            {contractor &&(
                <ContractorView>
                    {hasFault && (
                        <CustomButton onClick={toggleClearErrorDialog}>
                            Clear all alerts
                        </CustomButton>
                    )}
                </ContractorView>
            )}
            <AppPop
                title={confirmationState === 'confirm' ? "All alerts will be cleared. Proceed anyway?" : "Alerts Cleared!"}
                handleOpen={clearErrorDialogOpen}
                showActions="true"
                handleClose={() => {
                    toggleClearErrorDialog();
                    setConfirmationState('confirm');
                }}
                handleConfirm={async () => {
                    const success = await clearAllFaults();
                    if (success) {
                        setConfirmationState('cleared');
                        fetchFaults();
                    }
                }}
                confirmationState={confirmationState}
            />
            <StyledDrawer anchor="bottom" open={drawerOpen} onClose={handleDrawer}>
                {selectedFault &&
                    <AppFaultsCard
                        type={selectedFault.type === 0 ? 'Warning' : 'Lockout' }
                        fault={selectedFault.name}
                        occuranceDate={new Date(selectedFault.created * 1000).toLocaleDateString()}
                        frequency={selectedFault.frequency}
                    />
                }
            </StyledDrawer>
        </AppContainer>
    );
}

export default FaultsScreen;
