import React from 'react';
import {Box, Typography} from "@mui/material";
import {Pinwheel} from "@uiball/loaders";
import styled from "@emotion/styled";
import AppHeader from "./AppHeader";

const LoadingBox = styled(Box)({
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    border: "3px solid #37589D",
    borderRadius: "20px",
    padding: ".75em",
});

function AppLoader() {
    return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                <LoadingBox>
                    <Pinwheel size={70} lineWeight={3.5} speed={.85} color='#4EB6B5' />
                </LoadingBox>
                <Typography mt=".5em" color="#37589D" fontWeight="700">Gathering Data...</Typography>
            </Box>
    );
}

export default AppLoader;
