import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import {Box, Button, Card, Checkbox, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import AppButton from "../components/shared/AppButton";
import AppCard from "../components/shared/AppCard";
import {useNavigate, useParams} from "react-router-dom";
import AppAccordion from "../components/shared/AppAccordion";
import {useAlert} from "../contexts/AlertsProvider";
import {useUser} from "../contexts/UserProvider";
import {useApi} from "../contexts/ApiProvider";
import AppSwitch from "../components/shared/AppSwitch";

const outdoorUnitSteps = [
    {
        label: 'Heat pump placed on a flat and level (within 2) surface outside.',
        subtasks: []
    },
    {
        label: 'Heat pump clearances meet specs.',
        subtasks: [
            '24" (61 cm) clearance - Front Panel',
            '24” (61 cm) clearance – Flue Vent Side',
            '18” (46 cm) clearance – Opposite Side (electrical knockouts)',
            '18” (46 cm) clearance – Back Side',
        ],
    },
    {
        label: 'Natural gas line connected and has been leak checked per local requirements.',
        subtasks: ['Manual shutoff valve is installed before the drip leg outside of the heat pump.']
    },
    {
        label: 'The gas line has been purged of air.',
        subtasks: []
    },
    {
        label: 'Visual inspection of heat pump to ensure nothing was dislodged, disconnected, or\n' +
            'damaged during shipping and installation.',
        subtasks: []
    },
    {
        label: 'Confirm Flue Vent is properly installed and meets clearance requirements.',
        subtasks: []
    },
    {
        label: 'Confirm Condensate management was handled correctly and per local code requirements.',
        details: [
            'Option 1: Condensate line ran indoors to a drain or pump, line heat traced & insulated, a trap made somewhere along the line, and proper slope.',
            'Option 2: A hole dug with an insulated line dropping in & backfilled w/ Limestone\n' +
            'past the frost line.',
        ],
        subtasks: []
    },
    {
        label: 'Ensure all hydronic line isolation valves are open (as long as hydronic system install is complete).',
        subtasks: []
    },
    {
        label: 'All outdoor hydronic lines have been insulated with a minimum R-8 insulation.',
        subtasks: []
    },
    {
        label: 'Confirm 115 VAC is run to the GAHP through an electrical disconnect near the unit.',
        subtasks: []
    },
    {
        label: 'Confirm Low Voltage wires are routed separately from the High Voltage wires.',
        subtasks: []
    },
    {
        label: 'Confirm 115 VAC terminations in the GAHP control box are correct, as-in Hot is ran to the terminal labelled “L”, Neutral ran to “N”, and Ground to “G”.',
        subtasks: []
    },
    {
        label: 'Control wiring has been correctly installed in the GAHP control box. Perform any that apply:',
        details: [
            'Modbus Communication',
            'Thermostat Signals: W1, W2, WH, R',
            'GAHP Error Signal – when connected to custom ANESI AHU',
            'Hydronic Pump Relay (24VAC) – when applicable',
            'Indirect Storage Tank Temperature (Thermistor) – when applicable for\n' +
            'commercial water heating applications without an Aquastat',
        ],
        subtasks: []
    },
    {
        label: 'Confirm external antenna has been properly installed.',
        subtasks: []
    }
];

const turnOnOutdoorSteps = [
    {
        label: 'Confirm there are no active faults.',
        subtasks: []
    }
];

const combiApplicationsSteps = [
    {
        label: 'Confirm indirect storage tank is filled with water, both hot and cold domestic water valves are open, and an expansion tank is connected in the potable water piping feeding the tank.',
        subtasks: []
    },
    {
        label: 'Disconnect control wire from indirect storage water heater Aquastat.',
        subtasks: []
    },
    {
        label: 'Set the space heating thermostat such that a space heating call (Stage 1) is sent out.',
        subtasks: []
    },
    {
        label: 'Allow heat pump to turn on and confirm burner lights.',
        subtasks: []
    },
    {
        label: 'While the GAHP is running confirm there are no gas leaks inside the GAHP, in case something came loose or was damaged during shipment.',
        subtasks: []
    },
    {
        label: 'Measure the combustion emissions at the flue gas vent at close to full fire and minimum fire.',
        subtasks: []
    },
    {
        label: 'Set the combustion back to full fire and clock the gas consumption.',
        subtasks: []
    },
    {
        label: 'Re-connect water heater thermostat wiring.',
        subtasks: []
    },
    {
        label: 'Feel the hydronic lines feeding the storage tank. Confirm they are getting hot signifying\n' +
            'that the tank is being heated.',
        subtasks: []
    },
    {
        label: 'Clean up the work area, reinstall all panels, and walk through how the system operates with the homeowner.',
        subtasks: []
    }
];

const TaskStep = ({ step, index, checked, handleCheckboxChange, section }) => (

    <Box style={{ marginBottom: '0.3em'}}>
        <Card elevation={0} sx={{ border: 'dashed', borderWidth: '1px', borderColor: 'rgba(55,88,157,0.24)'}}>
            <Box display="flex" alignItems="center" >
                <Checkbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: '2em' }, color: '#37589D', '&.Mui-checked': {color: '#37589D'} }}
                    checked={checked[`${section}.${index}`] || false}
                    onChange={() => handleCheckboxChange(section, index)}
                />
                <Typography fontWeight="700" color="#37589D" fontSize="0.85em">
                    {step.label}
                </Typography>
            </Box>
            <Box pl={1}>
                {!step.details ? (
                    step.subtasks.map((subtask, i) => (
                        <Box key={i} display="flex" alignItems="center">
                            <Checkbox
                                sx={{ '& .MuiSvgIcon-root': { fontSize: '1em' }, color: '#37589D', '&.Mui-checked': {color: '#37589D'} }}
                                checked={checked[`${section}.${index}.${i}`] || false}
                                onChange={() => handleCheckboxChange(`${section}.${index}`, i)}
                            />
                            <Typography mt={1} color='#37589D' fontSize="0.85em" fontWeight="500">
                                {subtask}
                            </Typography>
                        </Box>
                    ))
                ) : (
                    step.details.map((detail, i) => (
                            <ul key={1}>
                                <Typography color='#37589D' fontSize="0.85em" fontWeight="600">
                                    <li>{detail}</li>
                                </Typography>
                            </ul>

                    ))
                )}
            </Box>
        </Card>
    </Box>
)

function ServicingAssistantScreen(props) {
    const alert = useAlert();
    const api = useApi();
    const navigate = useNavigate();
    const {user} = useUser();
    const contractor = user.role !== 0;
    const {unitId} = useParams();
    const [checked, setChecked] = useState({});
    const [showSignatureCard, setShowSignatureCard] = useState(false);
    const currentDate = new Date().toISOString().split("T")[0];
    const [totalCheckboxes, setTotalCheckboxes] = useState(0);
    const [checkedCount, setCheckedCount] = useState(0);
    const [isOnlySpaceHeating, setIsOnlySpaceHeating] = useState(false);

    const handleComplete = () => {
        setShowSignatureCard(!showSignatureCard);
    };

    const handleCheckboxChange = (section, index, subIndex = null) => {
        const key = subIndex !== null ? `${section}.${index}.${subIndex}` : `${section}.${index}`;
        setChecked(prevChecked => {
            const newChecked = { ...prevChecked, [key]: !prevChecked[key] };
            const newCheckedCount = Object.keys(newChecked).filter(k => newChecked[k]).length;
            setCheckedCount(newCheckedCount);
            const allCheckedAfterChange = newCheckedCount === totalCheckboxes;
            if (!allCheckedAfterChange && showSignatureCard) {
                setShowSignatureCard(false);
            }
            return newChecked;
        });
    };

    const handleSignature = async() => {
        const response = await api.post('/signature', {
            document_type: 'installation',
            unit_id: unitId,
        });
        if (response.ok) {
            alert('Successfully provided signature', 'success');
            navigate('/dashboard/my_units');
        } else {
            alert('Could not save signature. Please try again', 'error')
        }
    };

    useEffect(() => {
        let count = 0;
        [outdoorUnitSteps, turnOnOutdoorSteps, combiApplicationsSteps].forEach(steps => {
            steps.forEach(step => {
                count++;
                count += step.subtasks.length;
            });
        });
        setTotalCheckboxes(count);
    }, []);

    useEffect(() => {
        if (contractor === false) {
            navigate('/dashboard');
        }
    }, []);

    const toggleCheckAll = () => {
        const newCheckedState = {};
        let newCheckedCount = 0;

        if (!allChecked) {
            [outdoorUnitSteps, turnOnOutdoorSteps, combiApplicationsSteps].forEach((steps, sectionIndex) => {
                const section = ['outdoorUnit', 'turnOnOutdoor', 'combiApplications'][sectionIndex];
                steps.forEach((step, stepIndex) => {
                    const stepKey = `${section}.${stepIndex}`;
                    newCheckedState[stepKey] = true;
                    newCheckedCount++;

                    step.subtasks.forEach((_, subtaskIndex) => {
                        const subtaskKey = `${stepKey}.${subtaskIndex}`;
                        newCheckedState[subtaskKey] = true;
                        newCheckedCount++;
                    });
                });
            });
            setChecked(newCheckedState);
            setCheckedCount(newCheckedCount);
        } else {
            setChecked({});
            setCheckedCount(0);
            setShowSignatureCard(false);
        }
    };
    const allChecked = checkedCount === totalCheckboxes;

    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard/my_units/${unitId}`} title="Servicing Assistant" />
            <Typography fontSize="0.95em" color="#959292" style={{ textAlign: 'center', fontWeight: '700', marginBottom: '1em'}}>
                Follow the checklists for installation.
            </Typography>
            {/*<Box display="flex" justifyContent="center" alignItems="center" mb={2}>*/}
            {/*    <Button fullWidth startIcon={<CheckIcon />} variant="outlined" onClick={toggleCheckAll} sx={{ borderWidth: '2px', borderRadius: '9px', borderColor: '#4EB6B5', color:'#4EB6B5', fontWeight: '700' }} >*/}
            {/*        {allChecked ? "Un-check All" : "Check All"}*/}
            {/*    </Button>*/}
            {/*</Box>*/}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.3em' }}>
                <AppAccordion title="Outdoor Unit GAHP">
                    <Stepper orientation="vertical">
                        {outdoorUnitSteps.map((step, index) => (
                            <TaskStep
                                key={index}
                                step={step}
                                index={index}
                                section="outdoorUnit"
                                checked={checked}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </Stepper>
                </AppAccordion>
                <AppAccordion title="Turn on Outdoor Unit GAHP">
                    <Stepper orientation="vertical">
                        {turnOnOutdoorSteps.map((step, index) => (
                            <TaskStep
                                key={index}
                                step={step}
                                index={index}
                                section="turnOnOutdoor"
                                checked={checked}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </Stepper>
                </AppAccordion>
                <AppAccordion title="COMBI Applications">
                    <Box display="flex" mb="0.5em" alignItems="center">
                        <AppSwitch
                            checked={isOnlySpaceHeating}
                            onChange={(e) => setIsOnlySpaceHeating(e.target.checked)}
                        />
                        <Typography fontWeight="700" fontSize="0.9em" ml="0.5em">Space Heating Only</Typography>
                    </Box>
                    <Stepper orientation="vertical">
                        {combiApplicationsSteps.map((step, index) => {
                            if (isOnlySpaceHeating && [0, 1, 7, 8].includes(index)) {
                                return null;
                            }
                            return (
                                <TaskStep
                                    key={index}
                                    step={step}
                                    index={index}
                                    section="combiApplications"
                                    checked={checked}
                                    handleCheckboxChange={handleCheckboxChange}
                                />
                            );
                        })}
                    </Stepper>
                </AppAccordion>
            </Box>
            <AppButton style={{ marginTop: '2em'}} onClick={handleComplete} disabled={!allChecked} >
                Complete
            </AppButton>
            <Box mt={2} style={{ maxHeight: showSignatureCard ? '350px' : '0', overflow: 'hidden', transition: 'max-height 0.5s ease-in-out'}}>
                <AppCard type="good">
                    <Typography fontSize="1em" color="#959292" style={{ textAlign: 'center', fontWeight: '700', marginBottom: '1em'}} >Provide E-signature to acknowledge completion of all tasks.</Typography>
                    <Box display="flex" flexDirection="column" alignItems="center" marginBottom="1em" >
                        <TextField
                            variant="standard"
                            style={{ width: '85%' }}
                            InputProps={{
                                style: { borderBottom: '1px solid #F8F8F8' },
                                value: `${user.first_name} ${user.last_name}`
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { display: 'none' },
                            }}
                        />
                        <Typography fontSize="0.85em" color="#37589D" style={{ fontWeight: '500', marginTop: '0.5em'}}>E-signature</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography fontSize="1em" color="#37589D" style={{ fontWeight: '700' }}>{currentDate}</Typography>
                        <Typography fontSize="0.85em" color="#37589D" style={{ fontWeight: '500', marginTop: '0.5em'}}>Date</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <AppButton onClick={handleSignature} style={{ maxWidth: '35%', textAlign: 'center' }}>
                            Save
                        </AppButton>
                    </Box>
                </AppCard>
            </Box>
        </AppContainer>
    );
}

export default ServicingAssistantScreen;
