import React, {useEffect, useMemo, useState} from 'react';
import AppCard from "../shared/AppCard";
import AppButton from "../shared/AppButton";
import AppDivider from "../shared/AppDivider";
import AppSwitch from "../shared/AppSwitch";
import {Box, TextField, Typography, IconButton} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import styled from "@emotion/styled";
import {useUnits} from "../../contexts/UnitProvider";
import {useParams} from "react-router-dom";
import {useAlert} from "../../contexts/AlertsProvider";

const CardTitle = styled(Typography)({
    marginBottom: '0.25em',
    fontWeight: '700',
    color: '#959292',
});

const ControlContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
});

const VerticalContainer = styled(ControlContainer)({
    flexDirection: 'column',
});

const LabelTitle = styled(Typography)({
    fontSize: 16,
    marginBottom: '0.25em',
    marginTop: '0.5em',
    fontWeight: '700',
    '@media (max-width: 359px)': {
        fontSize: 15,
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        borderRadius: 12,
        width: '100%',
        height: '35px',
    },
    '& .MuiOutlinedInput-input': {
        padding: '1em',
        fontWeight: '700',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#37589D',
        },
        '&:hover fieldset': {
            borderColor: '#37589D',
        },
    },
});

const SetButtonWrapper = ({ isCheckVisible, ...props }) => <AppButton {...props} />;
const SetButton = styled(SetButtonWrapper)(({ isCheckVisible }) => ({
    borderRadius: 12,
    padding: '0px 0px',
    fontSize: '0.89rem',
    margin: isCheckVisible ? '0 0 0 15px' : '0 10px',
    height: '30px',
    animation: isCheckVisible ? 'slideLeft 0.5s forwards' : 'slideRight 0.5s forwards',
    '@media (min-width: 768px)': {
        width: '12em',
    },
}));

const CheckIconWrapper = ({ isCheckVisible, ...props }) => <CheckIcon {...props} />;
const AnimatedCheckIcon = styled(CheckIconWrapper)(({ isCheckVisible }) => ({
    animation: isCheckVisible ? 'fadeIn 0.2s' : 'fadeOut 0.5s',
    opacity: isCheckVisible ? 1 : 0,
}));

const FiringSetButton = styled(SetButton)({
    '@media (min-width: 768px)': {
        width: '12em',
    },
});

const ExpandableSection = styled(Box)({
    marginTop: '0.25em',
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.5s ease-in-out',
    '&.open': {
        maxHeight: '30em',
    },
});

const NewInputSection = styled(ExpandableSection)({
    transition: 'max-height 0.5s ease-in-out',
    '&.open': {
        maxHeight: '30em',
    },
});

const speedToAddress = {
    LOW: 20,
    MED: 21,
    HIGH: 22,
    G: 46,
    AC_SPD_Y1: 27,
    AC_SPC_Y2: 31,
    ESH_Y1: 42,
    ESH_Y2: 43,
};

const blowerSpeedDataIndices = {
    LOW: 76,
    MED: 77,
    HIGH: 78,
    G: 83,
    AC_SPD_Y1: 79,
    AC_SPC_Y2: 80,
    ESH_Y1: 81,
    ESH_Y2: 82,
};

const blowerLimits = {
    LOW: { min: 10, max: 30 },
    MED: { min: 30, max: 60 },
    HIGH: { min: 60, max: 100 },
    G: { min: 0, max: 100 },
    AC_SPD_Y1: { min: 40, max: 60 },
    AC_SPC_Y2: { min: 60, max: 100 },
    ESH_Y1: { min: 40, max: 60 },
    ESH_Y2: { min: 60, max: 100 },
};

// Check into redoing this page to use grid instead of boxes for the buttons and such.

function UnitController({unitType, unitData}) {
    const {unitId} = useParams();
    const alert = useAlert();
    const [inputValues, setInputValues] = useState({});
    const [switchStates, setSwitchStates] = useState({});
    const {writeRegister} = useUnits();
    const [actionSuccess, setActionSuccess] = useState({});
    const ENABLE_RELAY_CONTROL_INDEX_AHU = 0;
    const [isBlowerAdditionalOpen, setIsBlowerAdditionalOpen] = useState(false);
    const [blowerState, setBlowerState] = useState({
        switchState: false,
        speeds: {
            LOW: '',
            MED: '',
            HIGH: '',
            G: ''
        },
        additionalRegisters: {
            AC_SPD_Y1: '',
            AC_SPC_Y2: '',
            ESH_Y1: '',
            ESH_Y2: '',
        }
    });

    const controls = useMemo(() => ({
        GAHP: [
            { unitDataIndex: 40, address: 14, addressType: 'coil', turnOnAddress: 15,  type: 'switch', label: 'External Hyd Pump (24V-4)', switchValue: false},
            { unitDataIndex: 53, switchAddress: 28, switchAddressType: 'coil', setAddress: 315, setAddressType: 'holding', type: 'switch-input', label: 'Set Firing Rate', conLabel: '%', switchValue: false, inputValue1: 'Rate', buttonText: 'Set'},
            { unitDataIndex: 53, switchAddress: 28, switchAddressType: 'coil', setAddress1: 69,setAddress2: 68, setAddressType: 'holding',type: 'switch-input', label: 'Set Final Firing Rates', switchValue: false, inputValue1: 'Min', inputValue2: 'Max', buttonText: 'Set', conLabel: 'VDC', minButton: 'Min', maxButton: 'Max' },
        ],
        AHU: [
            { unitDataIndex: 32, address: 15, addressType: 'coil', type: 'switch', label: 'Enable Relay Control', switchValue: false },
            { unitDataIndex: 25, address: 8,  addressType: 'coil', type: 'switch', label: '3-way Value', switchValue: false },
            { unitDataIndex: 28, address: 11, addressType: 'coil', type: 'switch', label: 'Humidifier', switchValue: false },
            { unitDataIndex: 29, address: 12, addressType: 'coil', type: 'switch', label: 'Aux Pump Heating', switchValue: false},
            { unitDataIndex: 33, switchAddress: 16, switchAddressType: 'coil', setAddress: 256, setAddressType: 'holding', type: 'switch-input', label: 'Set Fan Speed', switchValue: false, inputValue: 'Fan Speed', buttonText: 'Set', conLabel: '%' },
        ],
    }[unitType]), [unitType]);

    useEffect(() => {
        const initialSwitchStates = {};
        controls.forEach((control, index) => {
            const unitDataValue = unitData[control.unitDataIndex];
            if (unitDataValue !== undefined) {
                initialSwitchStates[index] = unitDataValue[0] === 1;
            } else {
                console.warn(`No unitData found at index ${control.unitDataIndex}`);
            }
        });
        setSwitchStates(initialSwitchStates);
    }, [unitType, controls, unitData]);

    const handleSwitchChange = (index) => (event) => {
        const control = controls[index];
        if (!control) {
            console.warn(`No control found at index ${index}`);
            return;
        }
        if (unitType === 'AHU' && control.type === 'switch' && index !== ENABLE_RELAY_CONTROL_INDEX_AHU && !switchStates[ENABLE_RELAY_CONTROL_INDEX_AHU]) {
            return;
        }
        const isChecked = event.target.checked;
        setSwitchStates({
            ...switchStates,
            [index]: isChecked,
        });
        let address, addressType;
        let extraAddress = null;
        if (control.type === 'switch-input') {
            address = control.switchAddress;
            addressType = control.switchAddressType;
        } else if (control.label === 'External Hyd Pump (24V-4)' && unitType === 'GAHP') {
            address = control.address;
            addressType = control.addressType;
            extraAddress = control.turnOnAddress;
        } else {
            address = control.address;
            addressType = control.addressType;
        }
        if (address === undefined || addressType === undefined) {
            console.warn(`Missing address or addressType for control at index ${index}`);
            return;
        }
        const value = !!isChecked;
        writeRegister(unitId, address, addressType, value);
        if (extraAddress) {
            setTimeout(() => {
                writeRegister(unitId, extraAddress, addressType, value);
            }, 50);
        }
    };

    const handleBlowerSwitchChange = (event) => {
        const newSwitchState = event.target.checked;
        setBlowerState(prevState => ({
            ...prevState,
            switchState: newSwitchState
        }));
        if (!newSwitchState) {
            setIsBlowerAdditionalOpen(false);
        }
    };

    const handleBlowerSpeedChange = (registerKey, value) => {
        setBlowerState(prevState => {
            const isMainSpeed = prevState.speeds.hasOwnProperty(registerKey);
            return {
                ...prevState,
                speeds: isMainSpeed ? { ...prevState.speeds, [registerKey]: value } : prevState.speeds,
                additionalRegisters: !isMainSpeed ? { ...prevState.additionalRegisters, [registerKey]: value } : prevState.additionalRegisters
            };
        });
    };

    const handleSetButtonClick = (index) => {
        const control = controls[index];
        if (!control) {
            console.warn(`No control found at index ${index}`);
            return;
        }

        if (control.type !== 'switch-input') {
            console.warn(`Trying to handle set button click for non-switch-input control at index ${index}`);
            return;
        }
        const setAddress1 = control.setAddress1 || control.setAddress; // Fallback to setAddress if setAddress1 is not defined
        const setAddress2 = control.setAddress2;
        const setAddressType = control.setAddressType;
        if (setAddress1 === undefined || setAddressType === undefined) {
            console.warn(`Missing setAddress1 or setAddressType for control at index ${index}`);
            return;
        }
        const value = inputValues[index];
        if (setAddress1 && setAddress2) {
            const value1 = value.min;
            const value2 = value.max;
            writeRegister(unitId, setAddress1, setAddressType, value1);
            writeRegister(unitId, setAddress2, setAddressType, value2);
        }
        else if (setAddress1) {
            writeRegister(unitId, setAddress1, setAddressType, value);
        }
        else {
            console.warn(`Unable to write to register for control at index ${index}`);
        }
    };

    const handleInputChange = (index) => (event) => {
        const value = event.target.value;
        setInputValues({
            ...inputValues,
            [index]: value,
        });
    };

    const writeBlowerSpeed = async (key) => {
        const value = blowerState.speeds[key] || blowerState.additionalRegisters[key];
        const registerAddress = speedToAddress[key];
        const limits = blowerLimits[key];

        if (value === undefined) {
            return;
        }
        if (registerAddress === undefined) {
            return;
        }

        if (value < limits.min || value > limits.max) {
            console.warn(`Value ${value} for ${key} is out of limits: ${limits.min} - ${limits.max}`);
            return;
        }
        try {
            const response = await writeRegister(unitId, registerAddress, 'holding', value);
            if (response.ok) {
                setActionSuccess(prev => ({ ...prev, [key]: true }));
                setTimeout(() => setActionSuccess(prev => ({ ...prev, [key]: false })), 5000);
            } else {
                setActionSuccess(prev => ({ ...prev, [key]: false }));
            }
        } catch (error) {
            setActionSuccess(prev => ({ ...prev, [key]: false }));
        }
    };

    return (
        <>
            <CardTitle>
                {unitType === 'GAHP' ? 'Heat Pump Controls:' : 'Air Handler Controls:'}
            </CardTitle>
            <AppCard type="good">
                {controls.map((control, index) => (
                    <Box key={index}>
                        {control.type === 'switch' && (
                            <ControlContainer>
                                <LabelTitle>{control.label}</LabelTitle>
                                <AppSwitch
                                    checked={switchStates[index] || false}
                                    onChange={handleSwitchChange(index, control.address, control.addressType)}
                                />
                            </ControlContainer>
                        )}
                        {control.type === 'switch-input' && (
                            <VerticalContainer>
                                <ControlContainer>
                                        <LabelTitle>{control.label}</LabelTitle>
                                        <AppSwitch
                                            checked={switchStates[index] || false}
                                            onChange={handleSwitchChange(index)}
                                        />
                                </ControlContainer>
                                <ControlContainer>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '1em', justifyContent: 'center', opacity: switchStates[index] ? 1 : 0.25,
                                        pointerEvents: switchStates[index] ? 'auto' : 'none',}}>
                                        <Box>
                                            {control.label1 && <Typography style={{ fontSize: '1rem', textAlign: 'center' }}>{control.label1}</Typography>}
                                            <StyledTextField style={{ flex: 1, maxWidth: '100%' }} onChange={handleInputChange(index)} />
                                        </Box>
                                        {control.inputValue2 && (
                                            <Box style={{ marginLeft: '1em' }}>
                                                {control.label2 && <Typography style={{ fontSize: '1rem', textAlign: 'center' }}>{control.label2}</Typography>}
                                                <StyledTextField onChange={handleInputChange(index)} />
                                            </Box>
                                        )}
                                        {control.conLabel && <Typography style={{ marginLeft: '0.50em' }}>{control.conLabel}</Typography>}
                                        <SetButton customStyles={{width: '40%', alignSelf: 'flex-end'}} type="primary" onClick={() => handleSetButtonClick(index, control.address, control.addressType)}>{control.buttonText}</SetButton>
                                    </Box>
                                </ControlContainer>
                                {(control.minButton || control.maxButton) && (
                                    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <Typography style={{ display: 'flex', marginBottom: '.25em' }}>Presets:</Typography>
                                        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '.25em', opacity: switchStates[index] ? 1 : 0.25, pointerEvents: switchStates[index] ? 'auto' : 'none', }}>
                                            {control.minButton && (
                                                <Box style={{ textAlign: 'center', marginRight: '1em' }}>
                                                    <FiringSetButton type="primary">
                                                        {control.minButton}
                                                    </FiringSetButton>
                                                    <Typography variant="caption" >Current: {unitData[83][0]}</Typography>
                                                </Box>
                                            )}
                                            {control.maxButton && (
                                                <Box style={{ textAlign: 'center' }}>
                                                    <FiringSetButton type="primary">
                                                        {control.maxButton}
                                                    </FiringSetButton>
                                                    <Typography variant="caption" >Current: {unitData[82][0]}</Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </VerticalContainer>
                        )}
                        {index < controls.length - 1 && <AppDivider inCard="true" customPadding='0' />}
                    </Box>
                ))}
                {unitType === 'AHU' && (
                    <VerticalContainer>
                        <ControlContainer>
                            <LabelTitle>Set Final Blower Speeds</LabelTitle>
                            <AppSwitch
                                checked={blowerState.switchState}
                                onChange={handleBlowerSwitchChange}
                            />
                        </ControlContainer>
                        <ExpandableSection className={blowerState.switchState ? 'open' : ''}>
                            <ControlContainer>
                                <Box style={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                                    opacity: blowerState.switchState ? 1 : 0.25,
                                    pointerEvents: blowerState.switchState ? 'auto' : 'none'
                                }}>
                                    {Object.keys(blowerState.speeds).map((speed) => (
                                        <Box key={speed} style={{
                                            width: '100%', marginBottom: '0.5em',
                                            display: 'flex', alignItems: 'center'
                                        }}>
                                            <Box display="flex" flexDirection="column" mr="1em">
                                                <Typography style={{fontSize: '1rem', marginRight: '1em', width: '2em', fontWeight: "700"}}>
                                                    {speed}
                                                </Typography>
                                                <Typography fontSize="0.7em">Current: <span style={{ color: '#37589D', fontWeight: '700'}}>{unitData[blowerSpeedDataIndices[speed]][0]}</span></Typography>
                                            </Box>
                                            <StyledTextField
                                                style={{ flex: 1, maxWidth: '50%', marginRight: '0.5em' }}
                                                value={blowerState.speeds[speed]}
                                                onChange={(e) => handleBlowerSpeedChange(speed, e.target.value)}
                                                placeholder="..."
                                            />
                                            <Typography>
                                                %
                                            </Typography>
                                            <SetButton
                                                customStyles={{width: '25%', alignSelf: 'center'}}
                                                type="primary"
                                                onClick={() => writeBlowerSpeed(speed)}
                                                disabled={!blowerState.speeds[speed]}
                                                isCheckVisible={actionSuccess[speed]}
                                            >
                                                Set
                                            </SetButton>
                                            {actionSuccess[speed] && (
                                                <CheckIcon isCheckVisible={actionSuccess[speed]} style={{ color: '#4EB6B5' }} />
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </ControlContainer>
                            <Box display="flex" flexDirection='row' justifyContent="center" alignItems="center">
                                <Typography fontSize="0.9em" fontWeight="700" mr={1}>
                                    See additional settings
                                </Typography>
                                <IconButton onClick={() => setIsBlowerAdditionalOpen(!isBlowerAdditionalOpen)} disabled={!blowerState.switchState}>
                                    <ExpandMoreIcon style={{ transform: isBlowerAdditionalOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                                </IconButton>
                            </Box>
                        </ExpandableSection>
                        <NewInputSection className={blowerState.switchState && isBlowerAdditionalOpen ? 'open' : ''}>
                            <ControlContainer>
                                <Box style={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center',
                                    opacity: 1,
                                    pointerEvents: 'auto'
                                }}>
                                    {Object.keys(blowerState.additionalRegisters).map((register) => (
                                        <Box key={register} style={{ width: '100%', marginBottom: '0.5em', display: 'flex', alignItems: 'center' }}>
                                            <Box display="flex" flexDirection="column">
                                                <Typography style={{ fontSize: '1rem', marginRight: '0.5em', width: '5em' }}>
                                                    {register.replace(/_/g, ' ')}
                                                </Typography>
                                                <Typography fontSize="0.7em" >
                                                    Current: <span style={{ color: '#37589D', fontWeight: '700'}}>{unitData[blowerSpeedDataIndices[register]][0]}</span>
                                                </Typography>
                                            </Box>
                                            <StyledTextField
                                                style={{ flex: 1, maxWidth: '50%', marginRight: '0.5em' }}
                                                value={blowerState.additionalRegisters[register]}
                                                onChange={(e) => handleBlowerSpeedChange(register, e.target.value)}
                                                placeholder="..."
                                            />
                                            <Typography>
                                                %
                                            </Typography>
                                            <SetButton
                                                customStyles={{ width: '25%', alignSelf: 'center' }}
                                                type="primary"
                                                onClick={() => writeBlowerSpeed(register)}
                                                disabled={!blowerState.additionalRegisters[register]}
                                                isCheckVisible={actionSuccess[register]}
                                            >
                                                Set
                                            </SetButton>
                                            {actionSuccess[register] && (
                                                <AnimatedCheckIcon isCheckVisible={actionSuccess[register]} style={{ color: '#4EB6B5' }} />
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </ControlContainer>
                        </NewInputSection>
                    </VerticalContainer>
                )}
            </AppCard>
        </>
    );
}
export default UnitController;
