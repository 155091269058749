import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import AppCard from "../components/shared/AppCard";
import {useNavigate} from "react-router-dom";
import {useUnits} from "../contexts/UnitProvider";
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Card, CardContent, Collapse, CardHeader, useMediaQuery, Stack
} from "@mui/material";
import styled from "@emotion/styled";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TopicIcon from '@mui/icons-material/Topic';
import {useAlert} from "../contexts/AlertsProvider";
import SearchIcon from '@mui/icons-material/Search';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import AppLoader from "../components/shared/AppLoader";
import {useUser} from "../contexts/UserProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useApi} from "../contexts/ApiProvider";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import AppDrawer from "../components/shared/AppDrawer";
import {CreateGroupContent, ManageGroupUnitsContent, UnitInfoContent} from "../components/unitSpecific/UnitDrawerContent";
import UnitCard from "../components/unitSpecific/UnitCard";

const MainContainer = styled(Box) ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1em',
});

const InfoContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    justifyContent: 'space-evenly',
    alignItems: 'start',
    gap: '3em',
})

const UnitInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
})

const StyledCard = styled(AppCard)({
   borderColor: '#4EB6B5',
});

const StyledInvCard = styled(AppCard)(({ faultInUnits }) => ({
    '& .MuiCardContent-root:last-child': {
        padding: 5,
    },
    backgroundColor: faultInUnits ? '#B1494A' : '#4EB6B5',
    borderColor: 'transparent',
    color: '#FFFFFF',
    marginTop: '1em',
    marginBottom: '0.5em',
}));

const ContractorCard = styled(Card)({
    backgroundColor: '#37589D',
    color: 'white',
    marginTop: '1em',
    borderRadius: '10px',
});

const StyledSearchInput = styled(TextField)({
    marginTop: '2em',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#37589D',
            borderWidth: 1,
            borderRadius: 7,
        },
        '&.Mui-focused fieldset': {
            borderColor: '#37589D',
        },
        '& .MuiInputBase-input': {
            height: '2.5em',
            padding: '0 1em',
        },
    },
});

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#4EB6B5',
    },
    '& .MuiOutlinedInput-input': {
        padding: '0.25em 1em',
        fontSize: '1em',
        fontWeight: '700'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#4EB6B5',
            borderRadius: '1em',
            borderWidth: '1px',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4EB6B5',
        },
    },
});

const CustomFab = styled(SpeedDial)({
    '& .MuiSpeedDial-fab': {
        width: '2.5em',
        height: '2.5em',
        backgroundColor: '#37589D',
    }
});


function MyUnitsScreen(props) {
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const navigate = useNavigate();
    const {user} = useUser();
    const api = useApi();
    const contractor = user.role !== 0;
    const alert = useAlert();
    const {units, fetchAllUnits, fetchUnitGroups, unitGroups, saveGroup} = useUnits();
    const [hasUnits, setHasUnits] = useState(false);
    const [hasUnitGroups, setHasUnitGroups] = useState(false);
    const [faultInUnits, setFaultInUnits] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUnits, setFilteredUnits] = useState([]);
    const [searchGroupTerm, setSearchGroupTerm] = useState('');
    const [filteredGroupUnits, setFilteredGroupUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [groupDrawerOpen, setGroupDrawerOpen] = useState(false);
    const [groupUnitsOpen, setGroupUnitsOpen] = useState(false);
    const [openGroupDropdown, setOpenGroupDropdown] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [cardExpanded, setCardExpanded] = useState(false);
    const [groupsCardExpanded, setGroupsCardExpanded] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [selectedGroupUnits, setSelectedGroupUnits] = useState([]);
    const [selectedInGroup, setSelectedInGroup] = useState([]);
    const [selectedAvailableUnits, setSelectedAvailableUnits] = useState([]);
    const fullAddress = selectedUnit && selectedUnit.users
        ? (() => {
            const owner = selectedUnit.users.find(user => user.role === 0);
            return owner && owner.addresses && owner.addresses[0]
                ? `${owner.addresses[0].address_line_1}, ${owner.addresses[0].address_line_2}, ${owner.addresses[0].city}, ${owner.addresses[0].state_abbreviation} ${owner.addresses[0].zipcode}`
                : 'Not available';
        })()
        : 'Not available';
    const installLocationAddress = selectedUnit && selectedUnit.install_location_id
        ? `${selectedUnit.install_location.address_line_1}, ${selectedUnit.install_location.address_line_2 ? selectedUnit.install_location.address_line_2 + ', ' : ''}${selectedUnit.install_location.city}, ${selectedUnit.install_location.state_abbreviation} ${selectedUnit.install_location.zipcode}`
        : 'Not Available';
    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    const [editingGroupId, setEditingGroupId] = useState(false);
    const [unitsInGroup, setUnitsInGroup] = useState([]);
    const [unitsAvailable, setUnitsAvailable] = useState([]);
    const [selectedGroupName, setSelectedGroupName] = useState('');

    const handleDrawer = () => {setDrawerOpen(!drawerOpen)};
    const handleGroupDrawer = () => {setGroupDrawerOpen(!groupDrawerOpen)};

    const handleToolTip = () => {setTooltipOpen(!tooltipOpen)};
    const handleExpandClick = () => {setCardExpanded(!cardExpanded)};
    const handleOpen = () => setSpeedDialOpen(true);
    const handleClose = () => setSpeedDialOpen(false);

    useEffect(() => {
        const fetchUnits = async () => {
            setLoading(true);
            const response = await fetchAllUnits();
            if(response.ok) {
                if (response.body && response.body.length > 0) {
                    setHasUnits(true);
                }
            } else {
                navigate('/dashboard');
                alert('Enable to fetch units. Please try again', 'error');
            }
            setLoading(false);
        };
        fetchUnits();
    }, []);


    console.log(units)

    const fetchGroups = async () => {
        setLoading(true);
        const response = await fetchUnitGroups();
        if (response.ok) {
            if (response.body && response.body.length > 0) {
                setHasUnitGroups(true);
            }
        } else if (response.status === 404) {
            setHasUnitGroups(false);
        } else {
            setHasUnitGroups(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGroups();
    }, []);

    useEffect(() => {
        if (Array.isArray(units)) {
            const anyFaults = units.some(unit => Array.isArray(unit.faults) && unit.faults.length > 0);
            setFaultInUnits(anyFaults);
        }
        if (searchTerm === '') {
            setFilteredUnits(units);
            setCardExpanded(false);
        } else {
            const term = searchTerm.toLowerCase();
            setFilteredUnits(units.filter(unit => {
                const matchesSerialNumber = unit.serial_number.toLowerCase().includes(term);
                const matchesNameCustomer = unit.name_customer && unit.name_customer.toLowerCase().includes(term);
                const matchesNameContractor = unit.name_contractor && unit.name_contractor.toLowerCase().includes(term);
                return matchesSerialNumber || matchesNameCustomer || matchesNameContractor;
            }));
            setCardExpanded(true);
        }
    }, [searchTerm, units]);

    useEffect(() => {
        if (searchGroupTerm === '') {
            setFilteredGroupUnits(units);
        } else {
            const term = searchGroupTerm.toLowerCase();
            setFilteredGroupUnits(units.filter(unit => {
                const matchesSerialNumber = unit.serial_number.toLowerCase().includes(term);
                const matchesNameCustomer = unit.name_customer && unit.name_customer.toLowerCase().includes(term);
                const matchesNameContractor = unit.name_contractor && unit.name_contractor.toLowerCase().includes(term);
                return matchesSerialNumber || matchesNameCustomer || matchesNameContractor;
            }));
        }
    }, [searchGroupTerm, units]);


    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const toggleUnitSelection = (unitId) => {
        setSelectedGroupUnits(prevUnits => {
            if (prevUnits.includes(unitId)) {
                return prevUnits.filter(id => id !== unitId);
            } else {
                return [...prevUnits, unitId];
            }
        });
    };

    const handleSaveGroup = async() => {
        const response = await saveGroup(groupName, selectedGroupUnits);
        if (response.ok) {
            await fetchGroups();
            alert('Successfully created new group', 'success');
            setGroupDrawerOpen(false);
            setGroupName('');
            setSelectedGroupUnits([]);
        } else {
            alert('Unable to create new group', 'error');
        }
    };

    const handleDeleteGroup = async (groupId) => {
        const data = {'group_id': groupId}
        const response = await api.delete('/me/groups', data);
        if (response.ok) {
            await fetchGroups();
            alert('Group successfully deleted', 'success');
        } else {
            alert('Group could not be deleted', 'error');
        }
    };

    const moveUnits = async (direction) => {
        let unitsToAdd = [];
        let unitsToRemove = [];
        if (direction === 'toGroup') {
            if (selectedAvailableUnits.length === 0) {
                alert('A unit was not selected', 'error');
                return;
            }
            unitsToAdd = [...selectedAvailableUnits];
            const movedUnits = unitsAvailable.filter(unit => selectedAvailableUnits.includes(unit.id));
            setUnitsInGroup([...unitsInGroup, ...movedUnits]);
            setUnitsAvailable(unitsAvailable.filter(unit => !selectedAvailableUnits.includes(unit.id)));
            setSelectedAvailableUnits([]);
        } else if (direction === 'fromGroup') {
            if (selectedInGroup.length === 0) {
                alert('A unit was not selected', 'error');
                return;
            }
            unitsToRemove = [...selectedInGroup];
            const movedUnits = unitsInGroup.filter(unit => selectedInGroup.includes(unit.id));
            setUnitsAvailable([...unitsAvailable, ...movedUnits]);
            setUnitsInGroup(unitsInGroup.filter(unit => !selectedInGroup.includes(unit.id)));
            setSelectedInGroup([]);
        }
        try {
            const data = {
                'group_name': selectedGroupName,
                "unit_ids_add": [unitsToAdd][0],
                "unit_ids_remove": [unitsToRemove][0],
            };
            const response = await api.put('/me/groups', data);
            if (response.ok) {
                alert('Successfully updated the group', 'success')
                fetchGroups();
            } else {
                alert('Could not update group', 'error')
            }
        } catch (error) {
        }
    };

    const toggleSelected = (id, setFunc, selectedArray) => {
        if (selectedArray.includes(id)) {
            setFunc(selectedArray.filter(itemId => itemId !== id));
        } else {
            setFunc([...selectedArray, id]);
        }
    };

    const handleChangeName = async (groupId, oldName, newName) => {
        const data = {
            'group_name': oldName,
            'new_name': newName
        };
        const response = await api.put('/me/groups', data);
        if (response.ok) {
            await fetchGroups();
            alert('Group name successfully updated', 'success');
        } else {
            alert('Group name could not be updated', 'error');
        }
    };

    const handleUnitsDrawer = (groupId) => {
        const groupObject = unitGroups.find(group => group.id === groupId);
        const groupUnits = groupObject ? groupObject.units : [];
        const allUnits = units || [];
        const availableUnits = allUnits.filter(unit => !groupUnits.some(groupUnit => groupUnit.id === unit.id));

        if (groupObject && groupObject.name) {
            setSelectedGroupName(groupObject.name);
        }
        setUnitsInGroup(groupUnits);
        setUnitsAvailable(availableUnits);
        setGroupUnitsOpen(!groupUnitsOpen);
    };

    // Find better way to do this. Does not re-fetch on page refresh.
    const getUniqueContractors = (units) => {
        const uniqueContractors = new Set();
        const contractors = [];

        units.forEach(unit => {
            const contractor = unit.users.find(user => user.role !== 0);
            if (contractor && !uniqueContractors.has(contractor.id)) {
                uniqueContractors.add(contractor.id);
                contractors.push(contractor);
            }
        });
        return contractors;
    };

    if (loading) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard`} title="Installed Units" rightIcon={<AddBoxIcon />} onClickRight={() => navigate(`add_unit`)} />
            {hasUnits ? (
                <>
                <Box style={{ textAlign: 'center' }} mt={1} display="flex" flexDirection="column" alignItems="center" >
                    <Typography fontWeight="700" style={{ color: '#959292' }}>
                        {contractor ? 'Select a unit for more data.' : 'Choose your unit for more information.'}
                    </Typography>
                    {contractor && (
                        <>
                            <Box display="flex" alignItems="flex-end" justifyContent="center" width="100%">
                                <Box flex={1} maxWidth="5em">
                                    <hr />
                                </Box>
                                <Typography fontWeight="700" mx={1} style={{ color: '#37589D' }}>
                                    or
                                </Typography>
                                <Box flex={1} maxWidth="5em">
                                    <hr />
                                </Box>
                            </Box>
                            <Typography lineHeight="100%" fontWeight="700" style={{ color: '#959292' }}>
                                Quick scan with Info Icon.
                            </Typography>
                        </>
                    )}
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <StyledSearchInput
                        style={{ flex: 1 }}
                        variant="outlined"
                        placeholder="Search units..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon  style={{ color: '#37589D' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {contractor && (
                        <CreateNewFolderIcon
                            style={{ marginTop: '1em', marginLeft: '0.5em', fontSize: '30px', color: '#37589D', cursor: 'pointer'}}
                            onClick={handleGroupDrawer}
                        />
                    )}
                </Box>
                <StyledInvCard faultInUnits={faultInUnits}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        {faultInUnits ? (
                            <>
                                <Typography ml={1} mr={2} fontSize={isSmallScreen? "0.9em" : "1.125em"} fontWeight="700">Some units need attention</Typography>
                                <WarningIcon />
                            </>
                        ) : (
                            <>
                                <Typography ml={1} mr={2} fontSize={isSmallScreen? "0.9em" : "1.125em"} fontWeight="700">Good news, all units are OK</Typography>
                                <CheckIcon />
                            </>
                        )}
                    </Box>
                </StyledInvCard>
                <Card variant="outlined" style={{ borderRadius: '10px', marginTop: '0.5em', position: 'relative', backgroundColor: cardExpanded ? 'white' : '#FCFCFF' }}>
                    <CardHeader style={{ padding: '11px'}}
                        avatar={
                            <Inventory2Icon style={{ color: '#4EB6B5', fontSize: '1.5em' }} />
                        }
                        title={
                            <Typography fontWeight="700" color="#959292" fontSize="1.125em">
                                {contractor ? 'All Units' : 'My Units'}
                            </Typography>
                        }
                        subheader="Associated with me"
                        action={
                            <ExpandMore
                                expand={cardExpanded}
                                onClick={handleExpandClick}
                            >
                                <ExpandMoreIcon fontSize='large' style={{ color: '#37589D' }} />
                            </ExpandMore>
                        }
                    />
                    <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                        <CardContent style={{ maxHeight: '12em', overflowY: 'auto'}}>
                            <MainContainer>
                                {filteredUnits && filteredUnits.map((unit) => (
                                    <UnitCard
                                        key={unit.id}
                                        unit={unit}
                                        contractor={contractor}
                                        navigate={navigate}
                                        setDrawerOpen={setDrawerOpen}
                                        setSelectedUnit={setSelectedUnit}
                                    />
                                ))}
                            </MainContainer>
                        </CardContent>
                    </Collapse>
                </Card>
                {!contractor && (
                    <ContractorCard>
                        <CardHeader
                            title={
                                <Typography fontWeight="700" color="white">
                                    My Contractors
                                </Typography >
                            }
                        />
                        <CardContent>
                            <Stack spacing={1}>
                                {getUniqueContractors(filteredUnits).map((contractor) => (
                                    <Box key={contractor.id}>
                                        <Typography fontWeight="600">
                                            {contractor.first_name} {contractor.last_name}
                                        </Typography>
                                        <Typography>
                                            Phone: {contractor.phone_numbers.length > 0 ? contractor.phone_numbers[0].number : 'N/A'}
                                        </Typography>
                                        <Typography>
                                            Email: {contractor.email}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </CardContent>
                    </ContractorCard>
                )}
                {hasUnitGroups ? (
                    <Card variant="outlined" style={{ borderRadius: '10px', marginTop: '0.5em', position: 'relative', backgroundColor: groupsCardExpanded ? 'white' : '#FCFCFF' }}>
                        <CardHeader style={{ padding: '11px'}}
                            avatar={<WorkspacesIcon style={{ color: '#4EB6B5' }} />}
                            title={
                                <Typography fontWeight="700" color="#959292" fontSize="1.125em">
                                    My Groups
                                </Typography>
                            }
                            subheader="Specific groups of units"
                            action={
                                <ExpandMore
                                    expand={groupsCardExpanded}
                                    onClick={() => {
                                        setGroupsCardExpanded(!groupsCardExpanded);
                                    }}
                                >
                                    <ExpandMoreIcon fontSize='large' style={{ color: '#37589D' }} />
                                </ExpandMore>
                            }
                        />
                        <Collapse in={groupsCardExpanded}>
                            <CardContent>
                                {Object.values(unitGroups).map((group, index, array) => (
                                    <Card key={group.id} variant="outlined" style={{ marginBottom: index !== array.length - 1 ? '1em' : '0', borderRadius: '0.75em'}}>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ minHeight: '3em', padding: '0.5em' }}>
                                            <Box display="flex" alignItems="center">
                                                <TopicIcon style={{ marginLeft: '0.5em', color: '#37589D' }} />
                                                <Box ml={2}>
                                                    {editingGroupId === group.id ? (
                                                        <StyledTextField
                                                            autoFocus
                                                            defaultValue={group.name}
                                                            variant="outlined"
                                                            onBlur={(e) => {
                                                                handleChangeName(group.id, group.name, e.target.value);
                                                                setEditingGroupId(false);
                                                            }}
                                                        />
                                                    ) : (
                                                        <Typography color="#959292" fontWeight="700" fontSize="1.125em">
                                                            {group.name}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                            <IconButton onClick={() => setOpenGroupDropdown(openGroupDropdown !== group.id ? group.id : null)}>
                                                {openGroupDropdown === group.id ? (
                                                    <ExpandLessIcon style={{ color: '#37589D' }} />
                                                ) : (
                                                    <ExpandMoreIcon style={{ color: '#37589D' }} />
                                                )}
                                            </IconButton>
                                        </Box>
                                        <Collapse in={openGroupDropdown === group.id} timeout="auto" unmountOnExit>
                                            <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '1em', }}>
                                                {group.units.map(unit => (
                                                    <UnitCard
                                                        key={unit.id}
                                                        unit={unit}
                                                        contractor={contractor}
                                                        navigate={navigate}
                                                        setDrawerOpen={setDrawerOpen}
                                                        setSelectedUnit={setSelectedUnit}
                                                    />
                                                ))}
                                                {openGroupDropdown === group.id && (
                                                    <Box display="flex" justifyContent="flex-start" position="relative" mt={5}>
                                                        <CustomFab
                                                            ariaLabel="Anesi groups/units options"
                                                            icon={
                                                                <AddIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        transform: speedDialOpen ? 'rotate(45deg)' : 'rotate(0deg)',
                                                                        transition: 'transform 0.2s',
                                                                    }}
                                                                />
                                                            }
                                                            direction="right"
                                                            style={{ position: 'absolute', bottom: 0, left: 0 }}
                                                            onOpen={handleOpen}
                                                            onClose={handleClose}
                                                            open={speedDialOpen}
                                                        >
                                                            <SpeedDialAction
                                                                key="Delete"
                                                                icon={<DeleteIcon style={{color: 'darkred'}} fontSize="small" />}
                                                                tooltipTitle="Delete"
                                                                tooltipPlacement="bottom"
                                                                onClick={() => handleDeleteGroup(group.id)}
                                                            />
                                                            <SpeedDialAction
                                                                key="Edit Group"
                                                                icon={<MultipleStopIcon style={{color: 'darkgreen', fontSize: '28px'}} />}
                                                                tooltipTitle="Edit Group"
                                                                tooltipPlacement="bottom"
                                                                onClick={() => handleUnitsDrawer(group.id)}
                                                            />
                                                            <SpeedDialAction
                                                                key="Edit Name"
                                                                icon={<EditIcon style={{color: 'darkblue'}} fontSize="small" />}
                                                                tooltipTitle="Edit"
                                                                tooltipPlacement="bottom"
                                                                onClick={() => setEditingGroupId(group.id)}
                                                            />
                                                        </CustomFab>
                                                    </Box>
                                                )}
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                ))}
                            </CardContent>
                        </Collapse>
                    </Card>
                ) : (
                    contractor && (
                        <Typography mt={1} color="#959292" fontWeight="700" fontSize="1em" style={{ textAlign: 'center' }}>
                            No units are grouped yet...
                        </Typography>
                    )
                )}
                </>
            ) : (
                <Typography color="#959292" fontWeight="700" fontSize="1.25em" style={{ textAlign: 'center' }}>
                    No units are currently installed, click the '+' in the upper right to add.
                </Typography>
            )}
            <AppDrawer open={drawerOpen} onClose={handleDrawer} title="Quick Info">
                <UnitInfoContent
                    selectedUnit={selectedUnit}
                    loggedInUser={{ id: user.id, role: user.role }}
                    handleToolTip={handleToolTip}
                    tooltipOpen={tooltipOpen}
                    fullAddress={fullAddress}
                    installAddress={installLocationAddress}
                />
            </AppDrawer>
            <AppDrawer open={groupDrawerOpen} onClose={handleGroupDrawer} title="Create a new group">
                <CreateGroupContent
                    groupName={groupName}
                    setGroupName={setGroupName}
                    searchGroupTerm={searchGroupTerm}
                    setSearchGroupTerm={setSearchGroupTerm}
                    filteredGroupUnits={filteredGroupUnits}
                    selectedGroupUnits={selectedGroupUnits}
                    toggleUnitSelection={toggleUnitSelection}
                    handleSaveGroup={handleSaveGroup}
                />
            </AppDrawer>
            <AppDrawer open={groupUnitsOpen} onClose={handleUnitsDrawer} title={`Manage Units in: ${selectedGroupName}`}>
                <ManageGroupUnitsContent
                    selectedGroupName={selectedGroupName}
                    unitsInGroup={unitsInGroup}
                    selectedInGroup={selectedInGroup}
                    setSelectedInGroup={setSelectedInGroup}
                    toggleSelected={toggleSelected}
                    moveUnits={moveUnits}
                    unitsAvailable={unitsAvailable}
                    selectedAvailableUnits={selectedAvailableUnits}
                    setSelectedAvailableUnits={setSelectedAvailableUnits}
                    contractor={contractor}
                />
            </AppDrawer>
        </AppContainer>
    );
}

export default MyUnitsScreen;

