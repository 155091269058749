import React from 'react';
import { Switch } from "@mui/material";
import styled from "@emotion/styled";

const AppSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))({
    width: 42 * 0.9,
    height: 26 * 0.8,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2 * 0.8,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(' + (16) + 'px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#37589D',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#37589D',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#D1D1D1',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22 * 0.8,
        height: 22 * 0.8,
    },
    '& .MuiSwitch-track': {
        borderRadius: (26 / 2) * 0.8,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'background-color 500ms',
    },
});
export default AppSwitch;
