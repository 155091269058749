import React, { useState, useEffect } from 'react';
import AppContainer from "../shared/AppContainer";
import AppHeader from "../shared/AppHeader";
import styled from "@emotion/styled";
import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AppCard from "../shared/AppCard";
import AppButton from "../shared/AppButton";
import {Html5QrcodeScanner, Html5QrcodeScanType} from "html5-qrcode";

const Title = styled(Box)({
    display: 'flex',
    fontFamily: 'Jura',
    fontWeight: 700,
    fontSize: '1.25em',
    justifyContent: "center",
    marginTop: '2em',
    textAlign: 'center',
    color: '#959292',
    '@media (max-width: 359px)': {
        fontSize: '1em',
    },
});

const CardsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    marginTop: '2em',
});

const StyledCard = styled(AppCard)({
    borderColor: '#4EB6B5',
});

const ScannerContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
});

function AddUnitTwo(props) {
    const [serialSelected, setSerialSelected] = useState(false);
    const [serialNum, setSerialNum] = useState('');
    const [scanning, setScanning] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const [result, setResult] = useState('');

    const handleSerSelect = () => {
        setSerialSelected(true);
    };

    const handleSerInput = (ev) => {
        setSerialNum(ev.target.value);
        props.onSerialChange(ev.target.value);
    };

    const handleScanClick = () => {
        setScanning(true);
    };

    useEffect(() => {
        if (scanning) {
            const onScanSuccess = (decodedText) => {
                const serialNumber = decodedText.split('sn=')[1]?.split('&')[0];
                setResult(serialNumber);
                setSerialNum(serialNumber);
                props.onSerialChange(serialNumber);
                setScanning(false);
                html5QrcodeScanner.clear();
            };

            const onScanFailure = (error) => {
                console.log(`Code scan error = ${error}`);
            };

            const html5QrcodeScanner = new Html5QrcodeScanner(
                "reader",
                {
                    fps: 10,
                    qrbox: { width: 300, height: 300 },
                    supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
                },
                false
            );

            html5QrcodeScanner.render(onScanSuccess, onScanFailure);
        }
    }, [scanning]);

    return (
        <AppContainer style={{ width: '100%' }}>
            <AppHeader title="Add Unit" onBack={props.onBack} />
            <Title>
                How would you like to register the unit?
            </Title>
            <CardsContainer>
                {!scanning && (
                    <>
                        <StyledCard onClick={handleScanClick}>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1em' }}>
                                <Typography fontWeight="700" fontSize="1.75em">
                                    Scan
                                </Typography>
                                <QrCode2Icon style={{ fontSize: '2.50em' }} />
                            </Box>
                        </StyledCard>
                        <StyledCard onClick={handleSerSelect}>
                            {serialSelected ?
                                <TextField
                                    label="Enter Serial Number:"
                                    variant="standard"
                                    fullWidth
                                    value={serialNum}
                                    onChange={handleSerInput}
                                    InputProps={{ disableUnderline: true, style: { fontSize: isSmallScreen ? '0.9em' : '1em', fontWeight: '600' } }}
                                />
                                :
                                <Typography style={{ textAlign: 'center' }} fontWeight="700" fontSize={isSmallScreen ? "1.5em" : "1.75em"}>
                                    {result ? result : "Serial Number"}
                                </Typography>
                            }
                        </StyledCard>
                    </>
                )}
                {scanning && (
                    <ScannerContainer>
                        <div id="reader" style={{ width: '100%' }}></div>
                    </ScannerContainer>
                )}
            </CardsContainer>
            {!scanning && (
                <AppButton customStyles={{ marginTop: '2em' }} onClick={props.onSubmit} disabled={serialNum === ''}>
                    Submit
                </AppButton>
            )}
        </AppContainer>
    );
}

export default AddUnitTwo;


