import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import UnitHeader from "../components/unitSpecific/UnitHeader";
import styled from "@emotion/styled";
import DescriptionIcon from '@mui/icons-material/Description';
import WarningIcon from '@mui/icons-material/Warning';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HvacIcon from '@mui/icons-material/Hvac';
import {
    Badge,
    BottomNavigation,
    BottomNavigationAction,
    Box, Drawer, IconButton, TextField,
    Typography,
} from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useUnits} from "../contexts/UnitProvider";
import {useAlert} from "../contexts/AlertsProvider";
import AppLoader from "../components/shared/AppLoader";
import {useUser} from "../contexts/UserProvider";
import UnitStatus from "../components/unitSpecific/UnitStatus";
import My3DModel from "./ModelViewer";
import CloseIcon from "@mui/icons-material/Close";
import {CustomNameUtils} from "../Utils/CustomNameUtils";
import {useApi} from "../contexts/ApiProvider";

const StyledBottomNavigation = styled(BottomNavigation)({
    background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
    borderTopLeftRadius: '1em',
    borderTopRightRadius: '1em',
    borderTop: '2.5px solid transparent',
    height: '5em',
    '& .MuiBottomNavigationAction-label': {
        marginTop: '0.25em',
        fontWeight: '700',
    },
    '& .MuiBottomNavigationAction-root': {
        paddingTop: '0.25em',
    },
    '& .Mui-selected': {
        color: '#4EB6B5',
    },
    backgroundColor: '#FCFCFF',
});

const NewAppContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '1em',
    boxSizing: 'border-box',
    margin: '0.1em',
    flex: '1 0 auto',
    overflowY: 'auto',
});

const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
        borderTop: '7px solid transparent',
        borderRadius: '20px 20px 0 0',
        height: '90%',
    },
})

const MainDrawerContent = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
        padding: '0.25em 1em',
        fontSize: '1em',
        fontWeight: '700',
        marginLeft: '1rem'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#4EB6B5',
            borderRadius: '1em',
            borderWidth: '1px',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4EB6B5',
        },
    },
});

function UnitViewScreen(props) {
    const alert = useAlert();
    const {user} = useUser();
    const api = useApi();
    const {unitId} = useParams();
    const [hasFault, setHasFault] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {unit, unitType, setUnitType, unitData, getSpecificUnitData} = useUnits();
    const contractor = user.role !== 0;
    const [navValue, setNavValue] = useState(1);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [editNameState, setEditNameState] = useState({
        editing: false,
        unitName: '',
    });

    const handleDrawer = () => {setDrawerOpen(!drawerOpen)};

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getSpecificUnitData(unitId);
            console.log(response);
            if (response.ok) {
                const fetchedUnit = response.body.unit;
                console.log(fetchedUnit);
                setHasFault(fetchedUnit.faults && fetchedUnit.faults === true);
                const determinedUnitType = fetchedUnit.unit_model.name;
                setUnitType(determinedUnitType);
            } else if (response.status === 404) {
                alert('Access Denied: Account is not owner of this unit', 'error', 5);
                navigate('/dashboard');
            }
            setLoading(false);
        };
        fetchData();
        const interval = setInterval(() => {
            getSpecificUnitData(unitId, true);
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, [unitId, getSpecificUnitData, setHasFault, setUnitType]);

    if (loading || unit === undefined) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    } else if (unitData === null || unit === null || unitType === undefined) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={`/dashboard/my_units`} />
                <Typography fontWeight="700" fontSize="1.15em" align="center" color="#959292"> Data for this unit is currently unavailable. Please try again in a bit.</Typography>
            </AppContainer>
        );
    }

    const editName = async () => {
        if (editNameState.editing) {
            if (!editNameState.unitName.trim()) {
                setEditNameState(prevState => ({
                    ...prevState,
                    editing: false,
                    unitName: CustomNameUtils(unit, contractor),
                }));
                return;
            }
            const data = {
                unit_id: unitId,
                unit_name: editNameState.unitName,
            };
            try {
                const response = await api.put('/me/units', data);
                if (response.ok) {
                    getSpecificUnitData(unitId);
                } else {
                    alert('Failed to update the unit name', 'error', 3);
                }
            } catch (error) {
                alert('Error when trying to save unit name', 'error', 3);
            }
            setEditNameState(prevState => ({
                ...prevState,
                editing: false,
            }));
        } else {
            setEditNameState({
                editing: true,
                unitName: CustomNameUtils(unit, contractor),
            });
        }
    };

    return (
        <AppContainer fullwidth>
            <NewAppContainer>
                <AppHeader title={editNameState.editing ? (
                    <StyledTextField onChange={(e) => setEditNameState({ ...editNameState, unitName: e.target.value })} autoFocus placeholder={CustomNameUtils(unit, contractor)} />
                ) : (CustomNameUtils(unit, contractor))} backButtonRoute={`/dashboard/my_units`} rightText={editNameState.editing ? 'Save' : 'Edit'} onClickRight={editName} />
                <UnitHeader sn={unit.serial_number} status={hasFault ? 'ERROR' : 'OK'} />
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
                    <My3DModel src={`/models/${unitType.toLowerCase()}_model_${contractor ? 'contractor' : 'customer'}.glb`} />
                    <OpenInFullIcon style={{ color: '#37589D' }} onClick={handleDrawer} />
                </Box>
                <UnitStatus unitType={unitType} unitData={unitData} contractor={contractor} isMetric={user?.metric} />
            </NewAppContainer>
            <Box style={{ width: '100%', marginBottom: '0.5em'}}>
                <StyledBottomNavigation showLabels value={navValue} onChange={(ev, newValue) => {
                    setNavValue(newValue);
                    switch (newValue) {
                        case 0:
                        navigate(`more-data`);
                        break;
                        case 1:
                        navigate(`/dashboard/my_units/${unitId}`);
                        break;
                        case 2:
                        navigate(`documents`);
                        break;
                        case 3:
                        navigate(`alerts`);
                        break;
                    }
                }}>
                    {contractor && <BottomNavigationAction label="Controller" icon={<AssessmentIcon fontSize="large"/>} />}
                    <BottomNavigationAction label="Unit" icon={<HvacIcon fontSize="large"/>} />
                    <BottomNavigationAction label="Documents" icon={<DescriptionIcon fontSize="large"/>} />
                    <BottomNavigationAction label="Alerts" icon={
                        <Badge sx={{ '& .MuiBadge-dot': { width: 15, height: 15, borderRadius: '50%' } }} color="error" variant="dot" invisible={!hasFault}>
                            <WarningIcon fontSize="large"/>
                        </Badge>
                    }/>
                </StyledBottomNavigation>
            </Box>

            <StyledDrawer anchor="bottom" open={drawerOpen} onClose={handleDrawer}>
                <MainDrawerContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography fontWeight="700" fontSize="1.25em" color="#37589D">
                            Your GAHP Unit
                        </Typography>
                        <IconButton onClick={handleDrawer}>
                            <CloseIcon style={{ color: '#37589D', fontSize: 30}} />
                        </IconButton>
                    </Box>
                    <Typography fontWeight="700" textAlign="center" mt={3} color='#989898'>
                        This model is interactive
                    </Typography>
                    <My3DModel src={`/models/${unitType.toLowerCase()}_model_${contractor ? 'contractor' : 'customer'}.glb`} large={true} />
                    <Box mt="auto" display="flex" flexDirection="column-reverse" alignItems="center">
                        <Box width="70%">
                            <Typography fontWeight="700"  textAlign="center">
                                Better <span style={{color: '#4EB6B5'}}>Efficiency.</span>
                            </Typography>
                        </Box>
                        <Box width="70%">
                            <Typography fontWeight="700" textAlign="center">
                                Better <span style={{color: '#4EB6B5'}}>Comfort.</span>
                            </Typography>
                        </Box>
                        <Box width="70%">
                            <Typography fontWeight="700"  textAlign="center">
                                Better <span style={{color: '#4EB6B5'}}>Planet.</span>
                            </Typography>
                        </Box>
                    </Box>
                </MainDrawerContent>
            </StyledDrawer>
            <Outlet />
        </AppContainer>
    );
}

export default UnitViewScreen;
