import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import styled from "@emotion/styled";
import PersonIcon from '@mui/icons-material/Person';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LinkIcon from '@mui/icons-material/Link';
import AppCard from "../components/shared/AppCard";
import {Box, CardActionArea, IconButton, Typography, useMediaQuery} from "@mui/material";
import CloudIcon from '@mui/icons-material/Cloud';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import {Outlet, useNavigate} from 'react-router-dom'
import Logo from "../assets/Images/anesi-Logo-small.png";
import {useUser} from "../contexts/UserProvider";
import CircularProgress from '@mui/material/CircularProgress';
import Joyride, { STATUS } from 'react-joyride';
import {useUnits} from "../contexts/UnitProvider";
import {
    getCurrentGreeting,
    getWeather,
    getLocation,
    convertTemperature
} from "../Utils/DashboardUtils";

const AppContainerStyled = styled(AppContainer)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const CardsContainer = styled(Box) ({
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   marginTop: '1em',
});

const IconsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '1em',
});

const ClickableCard = styled(AppCard)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#4EB6B5',
    cursor: 'pointer',
    '@media (max-width: 359px)': {
        padding: '1em',
        height: '9vh',
    },
});

const CardIconContainer = styled(Box)({
    position: 'absolute',
    top: '0.5em',
    left: '0.5em',
    '@media (max-width: 360px)': {
        '& svg': {
            fontSize: '2em',
        },
    },
});

const CardActionAreaStyled = styled(CardActionArea)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root:last-child': {
        padding: 5,
    },
    backgroundColor: '#4EB6B5',
    borderColor: 'transparent',
    color: '#FFFFFF',
});

const SnapshotCard = styled(StyledCard)({
    padding: '0.5em',
    margin: '1em 0',
    backgroundColor: '#fff',
    borderColor: '#37589D',
});

const TilesContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '0.5em',
});

const Tile = styled(Box)((props) => ({
    padding: '1em',
    backgroundColor: props.bgcolor || '#37589D',
    borderRadius: '8px',
}));


function DashboardScreen(props) {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const { user } = useUser();
    const {units, fetchAllUnits} = useUnits();
    const [unitStats, setUnitStats] = useState({
        totalServicedUnits: 0,
        functionalUnits: 0,
        unitsWithErrors: 0,
    });
    const isMetric = user?.metric;
    const hasPermission = user?.permission_location;
    const [weatherPermissionDenied, setWeatherPermissionDenied] = useState(false);
    const contractor = user.role !== 0;
    const weatherApiKey = '3528bd613e48c32832d13ff157bc940b';
    const [weatherData, setWeatherData] = useState(null);
    const [location, setLocation] = useState(null);
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const [runTour, setRunTour] = useState(false);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        setSteps([
            {
                target: 'body',
                content: 'Welcome to your Anesi Dashboard! Let’s begin!',
                placement: 'center',
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
            },
            {
                target: '.my-account-button',
                content: 'Your account page gives you control of your personal info.',
                placement: 'bottom',
                disableBeacon: true,
            },
            {
                target: '.settings-button',
                content: 'Here you can edit your app preferences.',
                placement: 'bottom',
            },
            {
                target: '.clickable-card',
                content: 'View all your units and specific data here.',
                placement: 'bottom',
            },
            {
                target: '.snapshot',
                content: 'Get your daily snapshot here.',
                placement: 'bottom',
            },
        ]);
        const hasTakenTour = localStorage.getItem('hasTakenTour') === 'true';
        if (!hasTakenTour) {
            setRunTour(true);
        }
    }, []);

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('hasTakenTour', 'true');
            setRunTour(false);
        }
    };

    async function fetchWeatherData() {
        try {
            const position = await getLocation();
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            console.log(latitude, longitude)
            const weather = await getWeather(latitude, longitude, weatherApiKey);
            setWeatherData(weather);
            setWeatherPermissionDenied(false);
        } catch (error) {
            if (error.message.includes('denied')) {
                setWeatherPermissionDenied(true);
            }
        }
    }

    useEffect(() => {
        if (hasPermission && !weatherPermissionDenied) {
            fetchWeatherData();
        }
    }, [hasPermission, weatherPermissionDenied]);

    // Find a way to stop continuous fetch if no units in list.
    useEffect(() => {
        if (!units || units.length === 0) {
            fetchAllUnits();
        }
    }, [units]);

    // Find way to not show previous accounts cached results.
    useEffect(() => {
        if (units) {
            calculateUnitStats(units);
        }
    }, [units]);

    const calculateUnitStats = (units) => {
        const totalServicedUnits = units.length;
        const functionalUnits = units.filter(unit => unit.faults.length === 0).length;
        const unitsWithErrors = totalServicedUnits - functionalUnits;
        setUnitStats({
            totalServicedUnits,
            functionalUnits,
            unitsWithErrors,
        });
    };

    return (
        <AppContainerStyled>
            <Box>
                <Joyride
                    continuous
                    run={runTour}
                    steps={steps}
                    callback={handleJoyrideCallback}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    styles={{
                        options: {
                            primaryColor: '#4EB6B5',
                            fontFamily: 'Jura',
                            textColor: '#37589D',
                        },
                        tooltip: {
                            backgroundColor: '#fff',
                            borderRadius: '0.75em',
                            margin: '0.1em',
                        },
                        tooltipContent: {
                            fontSize: '1.15em',
                            fontFamily: 'Jura',
                            fontWeight: '700',
                        },
                        buttonNext: {
                            backgroundColor: '#4EB6B5',
                            border: '1px',
                            borderColor: 'white',
                        },
                        buttonBack: {
                            color: '#37589D',
                            fontFamily: 'Jura',
                            fontWeight: '700',
                            fontSize: '0.9em',
                            marginRight: '0.75em',
                        },
                        buttonSkip: {
                            fontWeight: '600',
                            color: 'grey',
                        },
                    }}
                />
                <IconsContainer>
                    <IconButton className="my-account-button" onClick={() => navigate('my_account')}>
                        <PersonIcon style={{ color: '#37589D', fontSize: '25px' }} />
                    </IconButton>
                    <IconButton className="settings-button" onClick={() => navigate('settings')}>
                        <SettingsApplicationsIcon style={{ color: '#37589D', fontSize: '25px' }} />
                    </IconButton>
                </IconsContainer>
                <Box display="flex" flexDirection="column" marginTop={1} mb={2} ml={'0.25em'}>
                    <Typography color="#37589D" style={{ fontWeight: '600' }} fontSize="1.35em">{getCurrentGreeting()}, {user.first_name}!</Typography>
                </Box>
                {hasPermission && !weatherPermissionDenied && (
                    <StyledCard>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <CloudIcon style={{ fontSize: isSmallScreen ? '1em' : '1.25em', marginLeft: '0.15em', color: '#FFFFFF', marginRight: '0.5em' }} />
                                {weatherData ? (
                                    <Typography fontSize={ isSmallScreen ? '0.9em' : '1em'} fontWeight="700" style={{ color: '#FFFFFF' }}>
                                        {weatherData.weather[0].description.charAt(0).toUpperCase() + weatherData.weather[0].description.slice(1)}
                                    </Typography>
                                ) : (
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <CircularProgress size="1em" style={{ marginLeft: '0.5em', color: '#FFFFFF' }} />
                                        <Typography fontWeight="700" ml={2}>Checking in on your city...</Typography>
                                    </Box>
                                )}
                            </Box>
                            {weatherData && (
                                <Box display="flex" flexDirection="column" alignItems="flex-start" mr={2}>
                                    <Typography fontSize=".90em" fontWeight="700" style={{ color: '#FFFFFF' }}>
                                        Temp: {convertTemperature(weatherData.main.temp, isMetric)}
                                    </Typography>
                                    <Typography fontSize=".90em" fontWeight="700" style={{ color: '#FFFFFF' }}>
                                        Humidity: {weatherData.main.humidity}%
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </StyledCard>
                )}
                <CardsContainer>
                    <ClickableCard className="clickable-card" onClick={() => navigate('my_units')}>
                        <CardIconContainer>
                            <LinkIcon style={{ color: '#37589D'}} fontSize="medium" />
                        </CardIconContainer>
                        <CardActionAreaStyled>
                            <Typography style={{ textAlign: 'center', fontWeight: '700', fontSize: '1.5em' }}>
                                {contractor ? 'Units Database' : 'My Residence'}
                            </Typography>
                        </CardActionAreaStyled>
                    </ClickableCard>
                </CardsContainer>
                {contractor && (
                    <SnapshotCard className="snapshot">
                        <Box mb={2}>
                            <Typography fontWeight="700" color='#37589D'>
                                My Daily Snapshot
                            </Typography>
                        </Box>
                        <TilesContainer>
                            <Tile bgcolor="#4EB6B5">
                                <Box display="flex" flexDirection="row" >
                                    <CheckIcon />
                                    <Typography ml={1} fontWeight="700">Running</Typography>
                                </Box>
                                <Typography fontSize="1.15em" fontWeight="700">{unitStats.functionalUnits}</Typography>
                            </Tile>
                            <Tile bgcolor="#B1494A">
                                <Box display="flex" flexDirection="row">
                                    <WarningIcon fontSize="small" />
                                    <Typography ml={1} fontWeight="700">In Fault</Typography>
                                </Box>
                                <Typography fontSize="1.15em" fontWeight="700">{unitStats.unitsWithErrors}</Typography>
                            </Tile>
                            <Tile style={{ gridColumn: '1 / -1' }}>
                                <Typography fontWeight="700">Total Serviced Units</Typography>
                                <Typography fontSize="1.15em" fontWeight="700">{unitStats.totalServicedUnits}</Typography>
                            </Tile>
                        </TilesContainer>
                    </SnapshotCard>
                )}
                <a href="https://anesicomfort.com/">
                    <Box display="flex" justifyContent="center" mt={2} mb={3}>
                        <img width={isDesktop ? '5%' : '15%'} src={Logo} alt="Anesi Logo"/>
                    </Box>
                </a>
            </Box>
            <Outlet/>
        </AppContainerStyled>
    );
}

export default DashboardScreen;
