import {useCallback, useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import AppAccordion from "../components/shared/AppAccordion";
import UnitHeader from "../components/unitSpecific/UnitHeader";
import UnitController from "../components/unitSpecific/UnitController";
import {Box, Typography} from "@mui/material";
import styled from "@emotion/styled";
import AppCard from "../components/shared/AppCard";
import {useUser} from "../contexts/UserProvider";
import {useNavigate, useParams} from "react-router-dom";
import {useUnits} from "../contexts/UnitProvider";
import AppLoader from "../components/shared/AppLoader";
import {useApi} from "../contexts/ApiProvider";
import {convertAndDetermineColor} from "../Utils/TempUtils";
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {CustomNameUtils} from "../Utils/CustomNameUtils";
import RemoteAccessIcon from "../assets/Icons/RemoteAccessIcon";

const AccordionWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25em',
    marginBottom: '1em',
});

const CardContent = styled(Box)({
   display: 'flex',
    flexDirection: 'column',
});

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root': {
        padding: 10,
    },
});

function ContractorDataScreen(props) {
    const api = useApi();
    const {user} = useUser();
    const {unitId} = useParams();
    const navigate = useNavigate();
    const {unit, unitType, unitData, getSpecificUnitData} = useUnits();
    const contractor = user.role !== 0;
    const [hasFault, setHasFault] = useState();
    const isMetric = user?.metric
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const fetchData = useCallback(() => {
        getSpecificUnitData(unitId, true)
            .then(() => {
                setIsLoading(false);
                setHasError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
    }, [unitId, getSpecificUnitData]);

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 3000);
        return () => clearInterval(interval);
    }, [fetchData]);

    useEffect(() => {
        if (!contractor) {
            navigate('/dashboard');
        } else {
            setHasFault(unit?.faults && unit?.faults === true);
        }
    }, [unit, contractor, navigate]);

    if (isLoading) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    if (hasError || !unit || unitData === null || (Array.isArray(unitData) && unitData.length === 0)) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={`/dashboard/my_units`} />
                <Typography fontWeight="700" fontSize="1.25em" align="center" color="#959292">Oops, looks like the connection was dropped.</Typography>
            </AppContainer>
        );
    }

    const StatusIndicator = ({ isOn }) => (
        <Chip
            icon={<FiberManualRecordIcon style={{ color: isOn ? '#4EB6B5' : '#B1494A' }} />}
            label={
                <Typography component="span" style={{ fontSize: '1em', fontWeight: '600' }}>
                    {isOn ? 'On' : 'Off'}
                </Typography>
            }
            variant="outlined"
            size="small"
        />
    );

    const renderAccordions = () => {
        if (unitType === 'GAHP') {
            return (
                <>
                    <AppAccordion title="Thermostat Inputs">
                        {unitData && unitData.slice(0, 6).map((item, index) => {
                            const isOn = item[0] === 1;
                            const registerName = item[1];

                            return (
                                <Box key={index} style={{ marginLeft: '1em', marginRight: '1em' }} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography fontWeight="600" mb={1}>{registerName}</Typography>
                                    <StatusIndicator isOn={isOn} />
                                </Box>
                            );
                        })}
                    </AppAccordion>
                    <AppAccordion title="Status of Relays">
                        {unitData && [46, 52, 40, 44, 50, 32, 76].map(index => {
                            const item = unitData[index];
                            const registerName = item[1];
                            return (
                                <Box key={index} style={{ marginLeft: '1em', marginRight: '1em' }} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography fontWeight="600" mb={1}>{registerName}</Typography>
                                    {index === 76 ? <Typography fontWeight="600" mb={1}>{item[0]} %</Typography> : <StatusIndicator isOn={item[0] === 1} />}
                                </Box>
                            );
                        })}
                    </AppAccordion>
                </>
            );
        } else if (unitType === 'AHU') {
            return (
                <>
                    <AppAccordion title="Thermostat Inputs and Outputs">
                        <Box display="flex" justifyContent="space-between">
                            <Box style={{ marginLeft: '1em', marginRight: '1em' }}>
                                {unitData && unitData.slice(0, 9).map((item, index) => {
                                    const isOn = item[0] === 1;
                                    const registerName = item[1];
                                    return (
                                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography fontWeight="600" mb={1} mr={1}>{registerName}</Typography>
                                            <StatusIndicator isOn={isOn} />
                                        </Box>
                                    );
                                })}
                            </Box>
                            <Box style={{ marginLeft: '1em', marginRight: '1em' }}>
                                {unitData && unitData.slice(15, 23).map((item, index) => {
                                    const isOn = item[0] === 1;
                                    const registerName = item[1];
                                    return (
                                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography fontWeight="600" mb={1} mr={1}>{registerName}</Typography>
                                            <StatusIndicator isOn={isOn} />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    </AppAccordion>
                    <AppAccordion title="All Temperatures">
                        {unitData && [45, 47, 48, 42, 41, 40, 43].sort((a, b) => a - b).map(index => {
                            const item = unitData[index];
                            let registerName = item[1].replace(/.*? - /, "").replace(/(Temperature|Teperature)\s*$/, "").trim();
                            const registerValue = item[0];
                            const { convertedValue, color } = convertAndDetermineColor(registerValue, isMetric);
                            return (
                                <Box key={index} style={{ marginLeft: '1em', marginRight: '1em' }} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography fontWeight="600" mb={1}>{registerName}</Typography>
                                    <Typography fontWeight="600" mb={1} style={{ color }}>{convertedValue}</Typography>
                                </Box>
                            );
                        })}
                    </AppAccordion>
                    <StyledCard type="good">
                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight="600" style={{ marginRight: '.5em' }}>Blower PWM:</Typography>
                                <Typography style={{ fontWeight: '700' }}>{unitData[74][0]} %</Typography>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </>
            );
        }
    };

    const openRemoteAccess = () => {
        window.open("https://mb-dev.28gorilla.com/", '_blank', 'noopener,noreferrer');
    };


    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard/my_units/${unitId}`} title="Unit Controller"  rightIcon={(user.role === 2 || user.role === 3) && <RemoteAccessIcon size="30" color="#37589D" />} onClickRight={openRemoteAccess} />
            <UnitHeader unit={CustomNameUtils(unit, contractor)} sn={unit.serial_number} status={hasFault ? 'ERROR' : 'OK'} />
            <AccordionWrapper>
                <Typography style={{ marginBottom: '0.125em' }} fontWeight="700" color="#959292">
                    Unit Condition:
                </Typography>
                {renderAccordions()}
            </AccordionWrapper>
            <UnitController unitData={unitData} unitType={unitType}/>
        </AppContainer>
    );
}

export default ContractorDataScreen;
