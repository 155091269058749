import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';
import {useApi} from "./ApiProvider";

const UserContext = createContext({});

export default function UserProvider({ children }) {
    const [user, setUser] = useState();
    const api = useApi();

    useEffect(() => {
        (async () => {
            if (api.isAuthenticated()) {
                try {
                    const response = await api.get('/me');
                    if (response.ok) {
                        setUser(response.body);
                    } else {
                        setUser(null);
                    }
                } catch (error) {
                    setUser(null);
                }
            } else {
                setUser(null);
            }
        })();
    }, [api]);

    const login = useCallback(async (email, password) => {
        const result = await api.login(email, password);
        if (result === 'ok') {
            const response = await api.get('/me');
            setUser(response.ok ? response.body : null);
        }
        return result;
    }, [api]);

    const logout = useCallback(async () => {
        await api.logout();
        setUser(null);
    }, [api]);

    return (
        <UserContext.Provider value={{ user, setUser, login, logout }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    return useContext(UserContext);
}
